import React, { useEffect, useState } from 'react';
import { 
    Button, 
    Card, 
    Form, 
    Input, 
    Modal, 
    Select, 
    Space, 
    Table, 
    message, 
    Typography, 
    Tag, 
    Tooltip, 
    Popconfirm,
    Badge,
    Row,
    Col,
    Divider,
    Alert,
    DatePicker,
    Dropdown,
    Menu,
    Switch
} from 'antd';
import { 
    DeleteOutlined, 
    EditOutlined, 
    PlusOutlined, 
    SearchOutlined,
    ReloadOutlined,
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    IdcardOutlined,
    ShopOutlined,
    CalendarOutlined,
    MoreOutlined
} from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';

const { Option } = Select;
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const PersonelDefinitionsList = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPersonel, setSelectedPersonel] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [companyList, setCompanyList] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        fetchData();
        fetchCompanyList();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/findAll', { 
                method: "findAll", 
                processor: "personelManagement", 
                data: { username: JSON.parse(localStorage.authdata).username } 
            });
            if (response.data.data) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching data: ", error);
            message.error("Veriler alınırken bir hata oluştu.");
        }
        setLoading(false);
    };

    const fetchCompanyList = async () => {
        try {
            const response = await axios.post('/companyList', { 
                method: "companyList", 
                processor: "company", 
                data: { username: JSON.parse(localStorage.authdata).username } 
            });
            if (response.data.data) {
                setCompanyList(response.data.data);
            }
        } catch (error) {
            console.error("Mağaza listesi alınırken hata oluştu:", error);
            message.error("Mağaza listesi alınamadı.");
        }
    };

    const handleDelete = async (record) => {
        try {
            await axios.post('/personelDelete', { 
                method: "personelDelete", 
                processor: "personelManagement", 
                data: { id: record.id, operator: JSON.parse(localStorage.authdata).username } 
            });
            message.success("Personel başarıyla silindi.");
            fetchData();
        } catch (error) {
            console.error("An error occurred while deleting personel: ", error);
            message.error("Personel silinirken bir hata oluştu.");
        }
    };

    const handleUpdate = (record) => {
        setSelectedPersonel(record);
        form.setFieldsValue({
            name: record.name,
            surname: record.surname,
            unvan: record.unvan,
            status: record.status,
            tcKimlikNo: record.tcKimlikNo,
            email: record.email,
            telefon: record.telefon,
            companyId: record.company?.id,
            startDate: record.startDate ? dayjs(record.startDate) : undefined,
            endDate: record.endDate ? dayjs(record.endDate) : undefined
        });
        setModalVisible(true);
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            
            // Tarihleri timestamp (long) formatına dönüştür
            const startDate = values.startDate ? values.startDate.valueOf() : null;
            const endDate = values.endDate ? values.endDate.valueOf() : null;
            
            if (selectedPersonel) {
                // Personel güncelleme
                const requestData = {
                    id: selectedPersonel.id,
                    name: values.name,
                    surname: values.surname,
                    unvan: values.unvan,
                    status: values.status,
                    tcKimlikNo: values.tcKimlikNo,
                    email: values.email || "",
                    telefon: values.telefon || "",
                    companyId: values.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    operator: JSON.parse(localStorage.authdata).username
                };

                await axios.post('/personelUpdate', { 
                    method: "personelUpdate", 
                    processor: "personelManagement", 
                    data: requestData 
                });
                message.success("Personel başarıyla güncellendi.");
            } else {
                // Yeni personel ekleme
                const requestData = {
                    name: values.name,
                    surname: values.surname,
                    unvan: values.unvan,
                    status: values.status,
                    tcKimlikNo: values.tcKimlikNo,
                    email: values.email || "",
                    telefon: values.telefon || "",
                    companyId: values.companyId,
                    startDate: startDate,
                    endDate: endDate,
                    operator: JSON.parse(localStorage.authdata).username
                };


                await axios.post('/personelAdd', { 
                    method: "personelAdd", 
                    processor: "personelManagement", 
                    data: requestData 
                });
                message.success("Personel başarıyla eklendi.");
            }
            
            setModalVisible(false);
            form.resetFields();
            fetchData();
        } catch (error) {
            console.error("An error occurred: ", error);
            message.error(selectedPersonel 
                ? "Personel güncellenirken bir hata oluştu." 
                : "Personel eklenirken bir hata oluştu.");
        }
    };

    const getColumns = () => {
        const baseColumns = [
            {
                title: "Ad Soyad",
                render: (_, record) => `${record.name} ${record.surname}`,
                sorter: (a, b) => `${a.name} ${a.surname}`.localeCompare(`${b.name} ${b.surname}`),
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder="Ad Soyad ara"
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => confirm()}
                                icon={<SearchOutlined />}
                                size="small"
                            >
                                Ara
                            </Button>
                            <Button onClick={() => clearFilters()} size="small">
                                Temizle
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: filtered => (
                    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
                onFilter: (value, record) =>
                    `${record.name} ${record.surname}`.toLowerCase().includes(value.toLowerCase()),
                fixed: 'left',
                width: isMobile ? 120 : 150,
            },
            {
                title: "Email",
                dataIndex: "email",
                ellipsis: true,
                render: (email) => (
                    <Space>
                        <MailOutlined />
                        <span>{email}</span>
                    </Space>
                ),
                responsive: ['lg'],
            },
            {
                title: "Telefon",
                dataIndex: "phone",
                render: (phone) => (
                    <Space>
                        <PhoneOutlined />
                        <span>{phone}</span>
                    </Space>
                ),
                responsive: ['md'],
            },
            {
                title: "Mağaza",
                dataIndex: ["company", "name"],
                filters: companyList.map(company => ({ text: company.name, value: company.name })),
                onFilter: (value, record) => record.company?.name === value,
                render: (name) => (
                    <Space>
                        <ShopOutlined />
                        <span>{name}</span>
                    </Space>
                ),
            },
            {
                title: "Durum",
                key: "status",
                render: (_, record) => {
                    const now = dayjs();
                    const startDate = record.startDate ? dayjs(record.startDate) : null;
                    const endDate = record.endDate ? dayjs(record.endDate) : null;
                    
                    let status = "Aktif";
                    let color = "green";
                    
                    if (!startDate || now.isBefore(startDate)) {
                        status = "Henüz Başlamadı";
                        color = "orange";
                    } else if (endDate && now.isAfter(endDate)) {
                        status = "Sona Erdi";
                        color = "red";
                    }
                    
                    return <Tag color={color}>{status}</Tag>;
                },
                filters: [
                    { text: 'Aktif', value: 'Aktif' },
                    { text: 'Henüz Başlamadı', value: 'Henüz Başlamadı' },
                    { text: 'Sona Erdi', value: 'Sona Erdi' },
                ],
                onFilter: (value, record) => {
                    const now = dayjs();
                    const startDate = record.startDate ? dayjs(record.startDate) : null;
                    const endDate = record.endDate ? dayjs(record.endDate) : null;
                    
                    let status = "Aktif";
                    
                    if (!startDate || now.isBefore(startDate)) {
                        status = "Henüz Başlamadı";
                    } else if (endDate && now.isAfter(endDate)) {
                        status = "Sona Erdi";
                    }
                    
                    return status === value;
                },
                responsive: ['sm'],
            },
            {
                title: "Başlangıç",
                dataIndex: "startDate",
                render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : "-",
                responsive: ['lg'],
            },
            {
                title: "Bitiş",
                dataIndex: "endDate",
                render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : "-",
                responsive: ['lg'],
            },
            {
                title: "Kayıt Tarihi",
                dataIndex: "createDate",
                render: (text) => dayjs(text).format('DD.MM.YYYY'),
                responsive: ['xl'],
            },
        ];

        if (isMobile) {
            baseColumns.push({
                title: "İşlem",
                key: "action",
                fixed: 'right',
                width: 60,
                render: (_, record) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'Düzenle',
                                    icon: <EditOutlined />,
                                    onClick: () => handleUpdate(record)
                                },
                                {
                                    key: '2',
                                    label: 'Sil',
                                    icon: <DeleteOutlined />,
                                    danger: true,
                                    onClick: () => {
                                        Modal.confirm({
                                            title: 'Silme Onayı',
                                            content: 'Personeli silmek istediğinize emin misiniz?',
                                            okText: 'Evet',
                                            cancelText: 'Hayır',
                                            onOk: () => handleDelete(record)
                                        });
                                    }
                                }
                            ]
                        }}
                        trigger={['click']}
                    >
                        <Button size="small" icon={<MoreOutlined />} />
                    </Dropdown>
                )
            });
        } else {
            baseColumns.push({
                title: "İşlemler",
                key: "action",
                fixed: 'right',
                width: 120,
                render: (_, record) => (
                    <Space size="middle">
                        <Tooltip title="Düzenle">
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => handleUpdate(record)}
                                size="small"
                            />
                        </Tooltip>
                        <Tooltip title="Sil">
                            <Popconfirm
                                title="Personeli silmek istediğinize emin misiniz?"
                                onConfirm={() => handleDelete(record)}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    size="small"
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            });
        }

        return baseColumns;
    };

    return (
        <Card 
            className="custom-table-card"
            title={
                <Space size="middle">
                    <Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                        Personel Listesi
                    </Title>
                    <Badge count={data.length} showZero color="#1890ff" />
                </Space>
            }
            extra={
                <Space>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            setSelectedPersonel(null);
                            form.resetFields();
                            setModalVisible(true);
                        }}
                    >
                        {!isMobile && "Yeni Personel"}
                    </Button>
                    <Tooltip title="Yenile">
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={fetchData}
                        />
                    </Tooltip>
                </Space>
            }
        >
            <Table
                className="custom-table"
                columns={getColumns()}
                dataSource={data}
                loading={loading}
                rowKey={record => record.id}
                pagination={{
                    ...tableParams.pagination,
                    showSizeChanger: !isMobile,
                    showQuickJumper: !isMobile,
                    showTotal: !isMobile ? (total) => `Toplam ${total} kayıt` : undefined,
                    size: isMobile ? 'small' : 'default',
                    pageSize: isMobile ? 5 : 10,
                }}
                onChange={(pagination, filters, sorter) => {
                    setTableParams({
                        pagination,
                        filters,
                        ...sorter,
                    });
                }}
                scroll={{ x: isMobile ? 800 : 1500, y: 500 }}
                rowSelection={!isMobile ? {
                    type: 'checkbox',
                    onChange: (_, selectedRows) => setSelectedRows(selectedRows),
                } : undefined}
                size={isMobile ? "small" : "middle"}
            />

            <Modal
                title={selectedPersonel ? "Personel Güncelle" : "Yeni Personel Ekle"}
                open={modalVisible}
                onOk={handleModalOk}
                onCancel={() => {
                    setModalVisible(false);
                    form.resetFields();
                }}
                width={isMobile ? "95%" : 700}
                destroyOnClose
                centered
                maskClosable={false}
                okText={selectedPersonel ? "Güncelle" : "Ekle"}
                cancelText="İptal"
                bodyStyle={{ 
                    padding: isMobile ? '12px' : '24px', 
                    maxHeight: '70vh', 
                    overflowY: 'auto' 
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Divider orientation="left">Kişisel Bilgiler</Divider>
                    
                    <Row gutter={isMobile ? 8 : 16}>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="name"
                                label="Adı"
                                rules={[{ required: true, message: 'Lütfen adını giriniz!' }]}
                            >
                                <Input 
                                    prefix={<UserOutlined />}
                                    placeholder="Adını giriniz" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="surname"
                                label="Soyadı"
                                rules={[{ required: true, message: 'Lütfen soyadını giriniz!' }]}
                            >
                                <Input 
                                    prefix={<UserOutlined />}
                                    placeholder="Soyadını giriniz" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Form.Item
                        name="tcKimlikNo"
                        label="TC Kimlik No"
                        rules={[
                            { required: true, message: 'Lütfen TC kimlik numarasını giriniz!' },
                            { len: 11, message: 'TC kimlik numarası 11 haneli olmalıdır!' },
                            { pattern: /^[0-9]+$/, message: 'TC kimlik numarası sadece rakamlardan oluşmalıdır!' }
                        ]}
                        tooltip="11 haneli TC kimlik numarası"
                    >
                        <Input 
                            prefix={<IdcardOutlined />}
                            placeholder="TC kimlik numarasını giriniz" 
                            maxLength={11}
                        />
                    </Form.Item>
                    
                    <Row gutter={isMobile ? 8 : 16}>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    // Zorunluluk kuralı kaldırıldı
                                    { type: 'email', message: 'Geçerli bir email adresi giriniz!' }
                                ]}
                            >
                                <Input 
                                    prefix={<MailOutlined />}
                                    placeholder="Email adresini giriniz (opsiyonel)" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="phone"
                                label="Telefon"
                                // Zorunluluk kuralı kaldırıldı
                            >
                                <Input 
                                    prefix={<PhoneOutlined />}
                                    placeholder="Telefon numarası giriniz (opsiyonel)" 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={isMobile ? 8 : 16}>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="unvan"
                                label="Ünvan"
                                rules={[{ required: true, message: 'Lütfen ünvan giriniz!' }]}
                            >
                                <Input 
                                    prefix={<UserOutlined />}
                                    placeholder="Ünvan giriniz" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="status"
                                label="Durum"
                                initialValue={true}
                                valuePropName="checked"
                            >
                                <Switch 
                                    checkedChildren="Aktif" 
                                    unCheckedChildren="Pasif" 
                                    defaultChecked 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Divider orientation="left">İş Bilgileri</Divider>
                    
                    <Form.Item
                        name="companyId"
                        label="Çalıştığı Mağaza"
                        rules={[{ required: true, message: 'Lütfen mağaza seçiniz!' }]}
                    >
                        <Select 
                            placeholder="Mağaza seçiniz"
                            showSearch
                            optionFilterProp="children"
                        >
                            {companyList.map(company => (
                                <Option key={company.id} value={company.id}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    
                    <Row gutter={isMobile ? 8 : 16}>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="startDate"
                                label="Başlangıç Tarihi"
                                tooltip="Personelin işe başlangıç tarihi"
                                rules={[{ required: true, message: 'Lütfen başlangıç tarihini seçiniz!' }]}
                            >
                                <DatePicker 
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                    placeholder="Başlangıç Tarihi"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <Form.Item
                                name="endDate"
                                label="Bitiş Tarihi"
                                tooltip="Personelin işten ayrılış tarihi (opsiyonel)"
                            >
                                <DatePicker 
                                    style={{ width: '100%' }}
                                    format="DD.MM.YYYY"
                                    placeholder="Bitiş Tarihi (opsiyonel)"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    {selectedPersonel && (
                        <Alert
                            message="Personel Güncelleme Bilgisi"
                            description={`${selectedPersonel.name} ${selectedPersonel.surname} personelini güncelliyorsunuz.`}
                            type="info"
                            showIcon
                            style={{ marginTop: 16 }}
                        />
                    )}
                </Form>
            </Modal>
        </Card>
    );
};

export default PersonelDefinitionsList;
