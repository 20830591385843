import React, { useState, useEffect, useContext } from "react";
import {
    DashboardOutlined,
    GlobalOutlined,
    SettingOutlined,
    UserOutlined,
    LogoutOutlined,
    IdcardTwoTone,
    DownloadOutlined,
    DownCircleOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    InfoCircleOutlined,
    ReloadOutlined,
    ShopOutlined,
    FileOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import { Button, Layout, Menu, message, Dropdown, Modal, Row, Col, Card, Calendar, List, Skeleton, Divider, Avatar, Collapse, Checkbox, Input, Tree, Spin, Space, Typography, Table, Badge, Progress, Tooltip, Empty, Statistic, Tag, Form, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../context";
import CompanyDefinitionsList from "../DataList/CompanyDefinitionsList";
import UserDefinitionsList from "../DataList/UserDefinitionsList";
import FileManagement from "../DataList/FileManagement";
import FileManagementUser from "../DataList/FileManagementUser";
import CompanyDetailPage from "../DataList/CompanyDetailPage";
import axios from "axios";
import logo from '../../img/sportinn1.png';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContactUs from "../DataList/ContactUs";
import MailDefinition from "../DataList/MailDefinition";
import PersonelDefinitionsList from "../DataList/PersonelDefinitionsList";
import FileTypeDefinitionList from "../DataList/FileTypeDefinitionList";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { jwtDecode } from "jwt-decode";
import config from "../../config";
import JitsiMeet from '../../components/JitsiMeet';
import Meet from "../Meet";
const { Option } = Select;

const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;
const { Search } = Input;
const { Title, Text } = Typography;

const Dashboard = () => {
    const version = "v2.7.3 BN: 3";
    const versionDate = "22.03.2025 04:12:51";
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const [value, dispatch] = useContext(MainContext);
    const [totalForms, setTotalForms] = useState(0);
    const [totalCompany, setTotalCompany] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedContent, setSelectedContent] = useState(null);
    const [data, setData] = useState([]);
    const [notFilePersoneldata, setNotFilePersonelData] = useState([]);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const user = JSON.parse(localStorage.getItem('authdata')).username;
    const isAdmin = JSON.parse(localStorage.authdata).userRole;
    const [isLicence, setIsLicence] = useState(null);
    const [personelModalOpen, setPersonelModalOpen] = useState(false);
    const [selectedCompanyForModal, setSelectedCompanyForModal] = useState(null);
    const [personelData, setPersonelData] = useState([]);
    const [page, setPage] = useState(1);
    const [personelListKey, setPersonelListKey] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [treeData, setTreeData] = useState([]);
    const [selectedMenuKey, setSelectedMenuKey] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [stores, setStores] = useState([]);
    const [personnel, setPersonnel] = useState([]);
    const [stats, setStats] = useState({
        storeCount: 0,
        totalForms: 0,
        completedForms: 0,
        pendingForms: 0,
        approvedForms: 0
    });
    const [generalInfo, setGeneralInfo] = useState({
        totalFile: 0,
        companies: [],
        totalCompany: 0,
        approvingFileSize: 0,
        rejectFileSize: 0,
        pendingFileSize: 0
    });
    const [formStatus, setFormStatus] = useState({
        approved: 0,
        rejected: 0,
        pending: 0
    });
    const [storeModalVisible, setStoreModalVisible] = useState(false);
    const [formYuklemeyenPersonel, setFormYuklemeyenPersonel] = useState([]);
    const [loadingNotFilePersonel, setLoadingNotFilePersonel] = useState(false);
    const [personelList, setPersonelList] = useState([]);
    const [meetModalVisible, setMeetModalVisible] = useState(false);
    const [meetForm] = Form.useForm();
    const [meetLoading, setMeetLoading] = useState(false);
    const [meetRoom, setMeetRoom] = useState(null);
    const [meetJwt, setMeetJwt] = useState(null);

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            if (window.innerWidth <= 768 && !collapsed) {
                setCollapsed(true);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [collapsed]);

    const fetchGeneralInfo = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/generalInfo', {
                method: "generalInfo",
                processor: "dashboardManagement",
                data: { username: user }
            });

            if (response.data && response.data.data) {
                setGeneralInfo(response.data.data);
            } else {
                message.error("Veriler alınamadı.");
            }
        } catch (error) {
            console.error('Genel bilgi yükleme hatası:', error);
            message.error("Veriler alınırken bir hata oluştu.");
        } finally {
            setLoading(false);
        }
    };

    const fetchPersonelList = async () => {
        try {
            const response = await axios.post('/findPersonelList', {
                method: "findPersonelList",
                processor: "files",
                data: {operator : user}
            });

            if (response.data && response.data.success === true) {
                // Form durumlarını hesapla
                const statusCounts = response.data.data.reduce((acc, item) => {
                    if (item.status === 'approved') acc.approved++;
                    else if (item.status === 'reject') acc.rejected++;
                    else acc.pending++;
                    return acc;
                }, { approved: 0, rejected: 0, pending: 0 });

                setFormStatus(statusCounts);
            }
        } catch (error) {
            console.error('Form durumları alınamadı:', error);
            message.error('Form durumları alınamadı.');
        }
    };

    useEffect(() => {
        if (personelModalOpen) {
            setPage(1);
            setPersonelListKey(prevKey => prevKey + 1);
        }
    }, [personelModalOpen]);

    const loadMoreData = async () => {
        if (scrollLoading || !hasMore) return;
        setScrollLoading(true);
        try {
            const response = await axios.post('/findPersonelList', { method: "findPersonelList", processor: "mainDashboard", data: {operator:user} });
            if (response.data && response.data.success === true) {
                const newData = response.data.data.map(item => ({
                    name: item.personel,
                    company: item.company
                }));

                setData(prevData => {
                    const updatedData = [...prevData, ...newData];
                    if (newData.length === 0 || newData.length < 10) {
                        setHasMore(false);
                    }
                    return updatedData;
                });
            }
        } catch (error) {
            message.error('Veriler alınamadı.');
        } finally {
            setScrollLoading(false);
        }
    };

    const loadMoreDataNotFilePersonelList = async () => {
        if (loadingNotFilePersonel) {
            return;
        }
        
        setLoadingNotFilePersonel(true);

        try {
            // API endpoint'inize ve gerekli parametrelere göre düzenleyin
            const response = await axios.post('/findPersonelListNotFile', {
                method: 'findPersonelListNotFile',
                processor: 'personel',
                data:{operator: user} // Kullanıcı adınızı burada kullanın
            });
            

            if (response.data && response.data.data) {
                setFormYuklemeyenPersonel(response.data.data || []);
            } else {
                message.error('Personel listesi alınamadı');
                setFormYuklemeyenPersonel([]);
            }
        } catch (error) {
            console.error('Form Yüklenmeyen Personel listesi yüklenirken hata:', error);
            message.error('Personel listesi yüklenirken bir hata oluştu');
            setFormYuklemeyenPersonel([]);
        } finally {
            setLoadingNotFilePersonel(false);
        }
        
        // Ekstra önlem - asenkron işlemin ardından loading'i kapatmak için
        setTimeout(() => {
            setLoadingNotFilePersonel(false);
        }, 500);
    };

    useEffect(() => {
        fetchCompanyList();
        fetchGeneralInfo();
        fetchPersonelList();
        loadMoreData();
        try {
            loadMoreDataNotFilePersonelList(); // Daha güvenli çağrı
        } catch (error) {
            console.error("Form yüklenmeyen personel listesi yüklenirken hata:", error);
            setLoadingNotFilePersonel(false); // Hata durumunda bile loading'i kapat
        }
    }, []);

    const fetchCompanyList = async () => {
        try {
            const response = await axios.post('/companyList', { method: "companyList", processor: "company", data: { username: user } });
            if (response.data.data) {
                const groupedCompanies = {};
                response.data.data.forEach(company => {
                    if (!groupedCompanies[company.country]) {
                        groupedCompanies[company.country] = [];
                    }
                    groupedCompanies[company.country].push(company);
                });

                const treeStructure = Object.entries(groupedCompanies).map(([country, companies]) => ({
                    title: country || 'Diğer',
                    key: `country-${country}`,
                    children: companies.map(company => ({
                        title: company.name,
                        key: company.id,
                        company: company,
                        isLeaf: true
                    }))
                }));

                setTreeData(treeStructure);
                setCompanyList(response.data.data);
            } else {
                message.error("Mağaza listesi alınamadı.");
            }
        } catch (error) {
            message.error("Mağaza listesi alınırken bir hata oluştu.");
        }
    };

    const handleSearch = (value) => {
        setSearchText(value.toLowerCase());
    };

    const getFilteredTreeData = () => {
        if (!searchText) return treeData;

        return treeData.map(countryNode => {
            const filteredChildren = countryNode.children.filter(company =>
                company.title.toLowerCase().includes(searchText)
            );

            if (filteredChildren.length > 0) {
                return {
                    ...countryNode,
                    children: filteredChildren
                };
            }
            return null;
        }).filter(Boolean);
    };

    const openPersonelModal = () => {
        setPersonelModalOpen(true);
    };

    const checkTokenExpiration = () => {
        try {
            const authStr = localStorage.getItem('authdata');
            if (authStr) {
                const authData = JSON.parse(authStr);

                if (authData && authData.token) {
                    const decodedToken = jwtDecode(authData.token);

                    if (decodedToken && decodedToken.exp) {
                        const currentTime = Date.now() / 1000;
                        if (decodedToken.exp < currentTime) {
                            // Logout işlemi
                            localStorage.removeItem('authdata');
                            dispatch({
                                type: "SIGN_IN",
                                payload: { signedIn: false }
                            });
                            navigate("/login");
                            return false;
                        }
                    }
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error("Token kontrolü hatası:", error);
            localStorage.removeItem('authdata');
            dispatch({
                type: "SIGN_IN",
                payload: { signedIn: false }
            });
            navigate("/login");
            return false;
        }
    };

    const checkLicence = () => {
        const authStr = localStorage.getItem('authdata');
        if (authStr) {
            const authData = JSON.parse(authStr);

            if (authData && authData.token && authData.username) {
                axios.post('/checkLicence', {
                    method: "checkLicence",
                    processor: "licence",
                    operator: authData.username
                }, {
                    headers: {
                        'Authorization': `Bearer ${authData.token}`
                    }
                })
                .then(response => {
                    setIsLicence(response.data.success);

                    if (response.data && !response.data.success) {
                        localStorage.removeItem('authdata');
                        dispatch({
                            type: "SIGN_IN",
                            payload: { signedIn: false }
                        });
                        navigate("/login");
                    }
                })
                .catch(error => {
                    console.error("Lisans kontrolü hatası:", error);
                });
            }
        }
    };

    useEffect(() => {
        // Önce token süresini kontrol et
        const isTokenValid = checkTokenExpiration();

        // Token geçerliyse lisans kontrolü yap
        if (isTokenValid) {
            checkLicence();
        }

        // Sayfa yenilemesini dinlemek için event listener ekle
        const handleRefresh = () => {
            checkTokenExpiration();
            checkLicence();
        };

        window.addEventListener('load', handleRefresh);

        // Component unmount olduğunda event listener'ı temizle
        return () => {
            window.removeEventListener('load', handleRefresh);
        };
    }, []);

    const handleDashboardButtonClick = () => {
        checkLicence();
        loadDashboardContent()
    };

    const loadDashboardContent = () => {
        if (loading) {
            setSelectedContent(<div>Loading...</div>);
        } else {
            setSelectedContent(
                    <div className="dashboard-container" style={{ padding: '16px' }}>
                        {/* Üst Bilgi */}
                        <Row gutter={[16, 16]} className="dashboard-header">
                            <Col span={24}>
                                <Card bordered={false}>
                                    <Title level={4}>Hoş Geldiniz, {user}</Title>
                                    <Text type="secondary">Son güncelleme: {new Date().toLocaleString()}</Text>
                                </Card>
                            </Col>
                        </Row>

                        <Divider />

                        {/* İstatistik Kartları - Daha Modern Tasarım */}
                        <Row gutter={[16, 16]} className="stat-cards">
                        <Col xs={24} md={8}>
                            <Card
                                className="stat-card"
                                loading={loading}
                                bordered={false}
                            >
                                <div onClick={handleStoreCardClick} style={{ cursor: 'pointer' }}>
                                    <Statistic
                                        title={<Space><ShopOutlined /> Toplam Mağaza</Space>}
                                        value={generalInfo.totalCompany}
                                        prefix={<Badge status="success" />}
                                    />
                                </div>
                                <Divider style={{ margin: '12px 0' }} />
                                <Progress
                                    percent={100}
                                    showInfo={false}
                                    strokeColor="#52c41a"
                                    size="small"
                                />
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card
                                className="stat-card"
                                loading={loading}
                                bordered={false}
                            >
                                <Statistic
                                    title={<Space><FileOutlined /> Toplam Form</Space>}
                                    value={generalInfo.totalFile}
                                    prefix={<Badge status="processing" />}
                                />
                                <Divider style={{ margin: '12px 0' }} />
                                <Progress
                                    percent={Math.round((generalInfo.totalFile / (generalInfo.totalCompany * 5)) * 100)}
                                    strokeColor={{
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                    }}
                                    size="small"
                                />
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card
                                className="stat-card"
                                loading={loading}
                                bordered={false}
                            >
                                <Statistic
                                    title={<Space><CheckCircleOutlined /> Onaylanan Formlar</Space>}
                                    value={generalInfo.approvingFileSize}
                                    prefix={<Badge status="success" />}
                                    valueStyle={{ color: '#52c41a' }}
                                />
                                <Divider style={{ margin: '12px 0' }} />
                                <Tooltip title={`${generalInfo.approvingFileSize} onaylı form`}>
                                    <Progress
                                        percent={Math.round((generalInfo.approvingFileSize / generalInfo.totalFile) * 100)}
                                        strokeColor="#52c41a"
                                        size="small"
                                    />
                                </Tooltip>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card
                                className="stat-card"
                                loading={loading}
                                bordered={false}
                            >
                                <Statistic
                                    title={<Space><CloseCircleOutlined /> Reddedilen Formlar</Space>}
                                    value={generalInfo.rejectFileSize}
                                    prefix={<Badge status="error" />}
                                    valueStyle={{ color: '#ff4d4f' }}
                                />
                                <Divider style={{ margin: '12px 0' }} />
                                <Tooltip title={`${generalInfo.rejectFileSize} reddedilen form`}>
                                    <Progress
                                        percent={Math.round((generalInfo.rejectFileSize / generalInfo.totalFile) * 100)}
                                        strokeColor="#ff4d4f"
                                        size="small"
                                    />
                                </Tooltip>
                            </Card>
                        </Col>

                        <Col xs={24} md={8}>
                            <Card
                                className="stat-card"
                                loading={loading}
                                bordered={false}
                            >
                                <Statistic
                                    title={<Space><ClockCircleOutlined /> Bekleyen Formlar</Space>}
                                    value={generalInfo.pendingFileSize}
                                    prefix={<Badge status="warning" />}
                                    valueStyle={{ color: '#faad14' }}
                                />
                                <Divider style={{ margin: '12px 0' }} />
                                <Tooltip title={`${generalInfo.pendingFileSize} bekleyen form`}>
                                    <Progress
                                        percent={Math.round((generalInfo.pendingFileSize / generalInfo.totalFile) * 100)}
                                        strokeColor="#faad14"
                                        size="small"
                                    />
                                </Tooltip>
                            </Card>
                        </Col>



                        {/* Personel Kartları - Özel Tasarım */}
                        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                            <Col xs={24}>
                                <Typography.Title level={4} style={{ margin: '8px 0 16px' }}>
                                    <UserOutlined style={{ marginRight: 8 }} />
                                    Personel Takip Durumu
                                </Typography.Title>
                            </Col>
                            
                            {/* Form Yüklenen Personel Listesi */}
                            <Col xs={24} md={12}>
                                <Card
                                    title={
                                        <Space>
                                            <CheckCircleOutlined style={{ color: '#52c41a' }} />
                                            <Typography.Title level={5} style={{ margin: 0 }}>Form Yüklenen Personel</Typography.Title>
                                        </Space>
                                    }
                                    className="dashboard-card"
                                    bordered={false}
                                    style={{ 
                                        height: '100%', 
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
                                        borderRadius: '8px',
                                        overflow: 'hidden'
                                    }}
                                    bodyStyle={{ 
                                        maxHeight: '400px', 
                                        overflow: 'auto', 
                                        padding: '12px' 
                                    }}
                                    headStyle={{
                                        backgroundColor: '#f6ffed',
                                        borderBottom: '1px solid #b7eb8f'
                                    }}
                                    extra={
                                        <Space>
                                            <Button 
                                                icon={<ReloadOutlined />} 
                                                onClick={() => {
                                                    loadMoreData();
                                                }} 
                                                type="text"
                                            />
                                            <Badge 
                                                count={Array.isArray(data) ? data.length : 0} 
                                                style={{ backgroundColor: '#52c41a' }} 
                                            />
                                        </Space>
                                    }
                                >
                                    {scrollLoading ? (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <Spin size="large" tip="Yükleniyor..." />
                                        </div>
                                    ) : Array.isArray(data) && data.length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={data}
                                            renderItem={item => (
                                                <List.Item 
                                                    actions={[
                                                        <Tooltip title="Detay">
                                                            <Button type="link" icon={<FileOutlined style={{ color: '#1890ff' }} />} />
                                                        </Tooltip>
                                                    ]}
                                                    style={{ 
                                                        padding: '12px 8px',
                                                        borderRadius: '4px',
                                                        transition: 'all 0.3s',
                                                        marginBottom: '4px',
                                                        background: '#fafafa'
                                                    }}
                                                    className="hover-list-item"
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar 
                                                                style={{ 
                                                                    backgroundColor: '#52c41a',
                                                                    border: '2px solid #d9f7be' 
                                                                }}
                                                            >
                                                                {item.name ? item.name[0] : "P"}
                                                            </Avatar>
                                                        }
                                                        title={<span style={{ fontWeight: 500 }}>{item.name.toUpperCase() || 'İsimsiz Personel'}</span>}
                                                        description={
                                                            <Space direction="vertical" size={0}>
                                                                <Text type="secondary">{item.company || '-'}</Text>
                                                            </Space>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Form yüklenen personel bulunamadı" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )}
                                </Card>
                            </Col>

                            {/* Form Yüklenmeyen Personel Listesi */}
                            <Col xs={24} md={12}>
                                <Card 
                                    title={
                                        <Space>
                                            <CloseCircleOutlined style={{ color: '#f5222d' }} />
                                            <Typography.Title level={5} style={{ margin: 0 }}>Form Yüklenmeyen Personel</Typography.Title>
                                        </Space>
                                    }
                                    className="dashboard-card"
                                    bordered={false}
                                    style={{ 
                                        height: '100%', 
                                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', 
                                        borderRadius: '8px',
                                        overflow: 'hidden'
                                    }}
                                    bodyStyle={{ 
                                        maxHeight: '400px', 
                                        overflow: 'auto', 
                                        padding: '12px' 
                                    }}
                                    headStyle={{
                                        backgroundColor: '#fff1f0',
                                        borderBottom: '1px solid #ffccc7'
                                    }}
                                    extra={
                                        <Space>
                                            <Button 
                                                icon={<ReloadOutlined />} 
                                                onClick={() => {
                                                    loadMoreDataNotFilePersonelList();
                                                }} 
                                                type="text"
                                            />
                                            <Badge 
                                                count={Array.isArray(formYuklemeyenPersonel) ? formYuklemeyenPersonel.length : 0} 
                                                style={{ backgroundColor: '#f5222d' }} 
                                            />
                                        </Space>
                                    }
                                >
                                    {loadingNotFilePersonel ? (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <Spin size="large" tip="Yükleniyor..." />
                                        </div>
                                    ) : Array.isArray(formYuklemeyenPersonel) && formYuklemeyenPersonel.length > 0 ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={formYuklemeyenPersonel}
                                            renderItem={item => (
                                                <List.Item 
                                                    // actions={[
                                                    //     <Tooltip title="Hatırlat">
                                                    //         <Button type="link" icon={<ClockCircleOutlined style={{ color: '#faad14' }} />} />
                                                    //     </Tooltip>
                                                    // ]}
                                                    style={{ 
                                                        padding: '12px 8px',
                                                        borderRadius: '4px',
                                                        transition: 'all 0.3s',
                                                        marginBottom: '4px',
                                                        background: '#fafafa'
                                                    }}
                                                    className="hover-list-item"
                                                >
                                                    <List.Item.Meta
                                                        avatar={
                                                            <Avatar 
                                                                style={{ 
                                                                    backgroundColor: '#f5222d',
                                                                    border: '2px solid #ffa39e' 
                                                                }}
                                                            >
                                                                {item.name ? item.name[0] : (item.personel ? item.personel[0] : "P")}
                                                            </Avatar>
                                                        }
                                                        title={<span style={{ fontWeight: 500 }}>{item.personel.toUpperCase() || 'İsimsiz Personel'}</span>}
                                                        description={
                                                            <Space direction="vertical" size={0}>
                                                                <Text type="secondary">{item.company || '-'}</Text>

                                                            </Space>
                                                        }
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <Empty description="Form yüklenmeyen personel bulunamadı" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Row>
                </div>
            );
        }
    };

    useEffect(() => {
        loadDashboardContent();
    }, [loading, data, formYuklemeyenPersonel, loadingNotFilePersonel]);

    // formYuklemeyenPersonel state'ini useEffect'te izleyecek şekilde debug amaçlı bir useEffect ekleyelim
    useEffect(() => {
    }, [formYuklemeyenPersonel, loadingNotFilePersonel]);

    const handleCompanyClick = async (company) => {
        setIsLoading(true);
        setSelectedCompanyId(company.id);
        setSelectedMenuKey(null);
        setSelectedContent(<CompanyDetailPage companyId={company.id} />);
        setIsLoading(false);
    };

    const handleMenuClick = ({ key }) => {
        setSelectedMenuKey(key);
        setSelectedCompanyId(null);

        switch (key) {
            case "dashboard":
                handleDashboardButtonClick();
                break;
            case "userDefinition":
                setSelectedContent(<UserDefinitionsList />);
                break;
            case "companyDefinition":
                setSelectedContent(<CompanyDefinitionsList refreshSider={refreshCompanyList} />);
                break;
            case "personelDefinition":
                setSelectedContent(<PersonelDefinitionsList />);
                break;
            case "formList":
                setSelectedContent(<FileManagement />);
                break;
            case "uploadedForms":
                setSelectedContent(<FileManagementUser />);
                break;
            case "contact":
                setSelectedContent(<ContactUs />);
                break;
            case "mailDefinition":
                setSelectedContent(<MailDefinition />);
                break;
            case "fileTypeDefinition":
                setSelectedContent(<FileTypeDefinitionList/>);
                break;
            case "meet":
                setSelectedContent(<Meet />);
                break;
            default:
                setSelectedContent(null);
        }
    };

    const logout = () => {
        dispatch({ type: "SIGN_OUT" });
        localStorage.removeItem("authdata");
        message.success('Başarıyla çıkış yapıldı.');
        navigate("/");
    };

    const sendRequestForDownloadTaahhutname = () => {
        axios
            .post("/download", { method: "download", processor: "files", data: { operator: user, files: "taahhutname" } })
            .then((response) => {
                if (response.status === 200) {
                    const formattedPath = response.data.data.path.replace('/home/seziko/repo/', config.downloadUrl);
                    //const formattedPath = response.data.data.path.replace('/home/sehoffice/repo/', 'http://89.252.142.4/rest-api/download/');

                    window.open(formattedPath, "_blank");
                } else {
                    message.error("İndirme sırasında bir sorun oluştu.");
                }
            })
            .catch(() => message.error("İndirme sırasında bir sorun oluştu."));
    };

    const dropdownMenu = {
        items: [
            {
                key: 'profile',
                icon: <UserOutlined style={{ color: '#1890ff' }} />,
                label: (
                    <div style={{ padding: '4px 0' }}>
                        <div style={{ fontWeight: 'bold' }}>{user}</div>
                        <div style={{ fontSize: '12px', color: '#8c8c8c' }}>Kullanıcı Profili</div>
                    </div>
                )
            },
            {
                type: 'divider'
            },
            // {
            //     key: 'taahhutname',
            //     icon: <DownloadOutlined style={{ color: '#52c41a' }} />,
            //     label: 'Taahhütname İndir',
            //     onClick: sendRequestForDownloadTaahhutname
            // },
            {
                key: 'version-info',
                icon: <InfoCircleOutlined style={{ color: '#722ed1' }} />,
                label: (
                    <div style={{ padding: '4px 0' }}>
                        <div style={{ fontSize: '12px', color: '#8c8c8c' }}>Versiyon: {version}</div>
                        <div style={{ fontSize: '12px', color: '#8c8c8c' }}>Tarih: {versionDate}</div>
                    </div>
                ),
                disabled: true
            },
            {
                type: 'divider'
            },
            {
                key: 'logout',
                icon: <LogoutOutlined style={{ color: '#ff4d4f' }} />,
                label: 'Çıkış Yap',
                onClick: logout,
                danger: true
            }
        ]
    };

    const menuItems = [
        { key: "dashboard", label: "Dashboard", icon: <DashboardOutlined /> },
        ...(isAdmin === 'ROLE_ADMIN' ? [{
            key: "definitions",
            label: "Tanımlar",
            icon: <SettingOutlined />,
            children: [
                { key: "userDefinition", label: "Kullanıcı Tanımları" },
                { key: "companyDefinition", label: "Mağaza Tanımları" },
                { key: "personelDefinition", label: "Personel Tanımları" },
                { key: "mailDefinition", label: "Mail Tanımları" },
            ]
        }] : []),
        { key: "uploadedForms", label: "Belge Yükle", icon: <SettingOutlined /> },
        ...(isAdmin === 'ROLE_ADMIN' ? [{
            key: "meet",
            label: "Toplantı Oluştur",
            icon: <GlobalOutlined />
        }] : [])
    ];

    const companyDropdownMenu = (company) => (
        <Menu>
            <Menu.Item key="1" onClick={() => handlePersonelListClick(company)}>
                Personel Listesi
            </Menu.Item>
        </Menu>
    );

    const handlePersonelListClick = async (company) => {
        setSelectedCompanyForModal(company);

        try {
            const response = await axios.post('/findPersonelListWithCompany', {
                method: "findPersonelListWithCompany",
                processor: "personelManagement",
                data: { id: company.id , operator:user}
            });
            if (response.data.data) {
                setPersonelData(response.data.data);
            } else {
                message.error("Personel listesi alınamadı.");
            }
        } catch (error) {
            message.error("Personel listesi alınırken bir hata oluştu.");
        }

        setPersonelModalOpen(true);
    };

    const onTreeSelect = (selectedKeys, info) => {
        if (info.node.isLeaf) {
            handleCompanyClick(info.node.company);
        } else {
            const key = info.node.key;
            setExpandedKeys(prevKeys => {
                if (prevKeys.includes(key)) {
                    return prevKeys.filter(k => k !== key);
                } else {
                    return [...prevKeys, key];
                }
            });
        }
    };

    const handleSiderCollapse = (collapsed) => {
        setCollapsed(collapsed);
        if (collapsed) {
            setSearchText('');
        }
    };

    const refreshCompanyList = () => {
        fetchCompanyList();
    };

    // Takvim için form sayısını hesaplama fonksiyonu
    const getFormCountForDate = (date) => {
        // Bu fonksiyon, belirli bir tarihte kaç form olduğunu hesaplar
        // Örnek implementasyon:
        const formDates = personnel.map(p => p.lastUpdate);
        return formDates.filter(d =>
            dayjs(d).format('YYYY-MM-DD') === date.format('YYYY-MM-DD')
        ).length;
    };

    // Stats değerlerini destructure et
    const { storeCount, totalForms: totalFormsFromStats, completedForms, pendingForms, approvedForms } = stats;

    const handleRowClick = (record, fileType) => {
        const filteredFile = record.files.find(f => f.fileType === fileType);
        // ...
    };

    // Toplam Mağaza kartı için tıklama işleyicisi
    const handleStoreCardClick = () => {
        setStoreModalVisible(true);
    };

    const loadUncompletedPersonelData = async () => {
        try {
            setLoading(true); // Yükleme başladığında
            
            const response = await axios.post('/endpoint', { 
                method: "getUncompletedPersonel", 
                processor: "personelManagement" 
                // Gerekli diğer parametreler
            });
            
            if (response.data && response.data.success) {
                setPersonelList(response.data.data); // Veriyi state'e kaydet
            } else {
                message.error("Personel verisi yüklenemedi");
            }
        } catch (error) {
            console.error("Personel verisi yüklenirken hata:", error);
            message.error("Personel verisi yüklenirken bir hata oluştu");
        } finally {
            setLoading(false); // Başarılı veya başarısız, yükleme tamamlandığında
        }
    };

    const handleMeetCreate = async (values) => {
        setMeetLoading(true);
        try {
            const response = await axios.post('/createMeet', {
                method: "createMeet",
                processor: "meet",
                data: {
                    username: values.username,
                    password: values.password,
                    operator: user,
                    moderator: values.moderator
                }
            });

            if (response.data && response.data.success && response.data.data && response.data.data.body) {
                const { jwt, meetingUrl, roomName } = response.data.data.body;
                setMeetJwt(jwt);
                setMeetRoom(roomName);
                message.success('Toplantı başarıyla oluşturuldu');
            } else {
                message.error('Toplantı oluşturulurken bir hata oluştu');
            }
        } catch (error) {
            console.error('Toplantı oluşturma hatası:', error);
            message.error('Toplantı oluşturulurken bir hata oluştu');
        }
        setMeetLoading(false);
    };

    const handleMeetClose = async (roomName) => {
        try {
            await axios.post('/closeMeet', {
                method: "closeMeet",
                processor: "meet",
                data: {
                    roomName: roomName,
                    operator: user
                }
            });
            message.success('Toplantı sonlandırıldı');
            // Modal'ı kapat ve state'leri sıfırla
            setMeetModalVisible(false);
            setMeetRoom(null);
            setMeetJwt(null);
        } catch (error) {
            message.error('Toplantı sonlandırılırken bir hata oluştu');
        }
    };

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header
                style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#001529",
                    position: 'fixed',
                    zIndex: 1,
                    width: '100%',
                    padding: isMobile ? '0 10px' : '0 50px'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {isMobile && (
                        <Button
                            type="primary"
                            onClick={() => handleSiderCollapse(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 40,
                                height: 40,
                                backgroundColor: '#1890ff',
                                borderColor: '#1890ff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 10
                            }}
                        >
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </Button>
                    )}
                    <img
                        src={logo}
                        alt="Logo"
                        style={{
                            width: isMobile ? 120 : 200,
                            height: isMobile ? 60 : 120,
                            marginRight: isMobile ? 10 : 20,
                            cursor: 'pointer'
                        }}
                    />
                </div>

                {!isMobile && (
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        items={menuItems}
                        onClick={handleMenuClick}
                        selectedKeys={[selectedMenuKey]}
                        style={{ flex: 12 }}
                    />
                )}

                <Dropdown menu={dropdownMenu} trigger={['click']}>
                    <Button
                        type="primary"
                        style={{
                            marginLeft: 'auto',
                            height: '40px',
                            padding: '0 15px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            backgroundColor: '#001529',
                            borderColor: '#001529',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}
                    >
                        <Space>
                            <Avatar
                                size="small"
                                icon={<UserOutlined />}
                                style={{
                                    backgroundColor: '#fff',
                                    color: '#001529'
                                }}
                            />
                            {!isMobile && <span>{user}</span>}
                        </Space>
                    </Button>
                </Dropdown>
            </Header>

            <Layout style={{ marginTop: 64 }}>
                <Sider
                    collapsible={!isMobile}
                    collapsed={collapsed}
                    onCollapse={handleSiderCollapse}
                    theme="dark"
                    width={250}
                    collapsedWidth={isMobile ? 0 : 80}
                    breakpoint="lg"
                    style={{
                        height: "calc(100vh - 64px)",
                        overflowY: "auto",
                        position: "fixed",
                        top: 64,
                        left: 0,
                        zIndex: 1
                    }}
                    className="custom-sider"
                >
                    {isMobile && (
                        <div style={{ padding: '16px', borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                            <Menu
                                theme="dark"
                                mode="inline"
                                items={menuItems}
                                onClick={(info) => {
                                    handleMenuClick(info);
                                    setCollapsed(true);
                                }}
                                selectedKeys={[selectedMenuKey]}
                            />
                        </div>
                    )}

                    <div style={{padding: '16px'}}>
                        {!collapsed && (
                            <Search
                                placeholder="Mağaza ara..."
                                onChange={(e) => handleSearch(e.target.value)}
                                value={searchText}
                                style={{
                                    marginBottom: 8,
                                    backgroundColor: 'transparent'
                                }}
                            />
                        )}
                    </div>

                    <Tree
                        treeData={getFilteredTreeData()}
                        onSelect={onTreeSelect}
                        selectedKeys={selectedCompanyId ? [selectedCompanyId] : []}
                        expandedKeys={expandedKeys}
                        onExpand={(newExpandedKeys) => setExpandedKeys(newExpandedKeys)}
                        style={{
                            backgroundColor: 'transparent',
                            color: 'rgba(255, 255, 255, 0.65)'
                        }}
                        className="custom-dark-tree"
                    />
                </Sider>

                <Layout style={{
                    marginLeft: collapsed ? (isMobile ? 0 : '80px') : '250px',
                    transition: 'margin-left 0.2s'
                }}>
                    <Content style={{
                        padding: isMobile ? "12px" : "24px",
                        marginTop: 64
                    }}>
                        {isLoading ? (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '200px'
                            }}>
                                <Spin size="large" tip="Yükleniyor..." />
                            </div>
                        ) : (
                            selectedContent
                        )}
                    </Content>
                    <Footer style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        padding: isMobile ? '12px' : '24px'
                    }}>
                        ©2024 SEHOFFICE
                    </Footer>
                </Layout>
            </Layout>

            <Modal
                title={`${selectedCompanyForModal?.name || ''} Personel Listesi`}
                open={personelModalOpen}
                onCancel={() => setPersonelModalOpen(false)}
                footer={null}
                width={isMobile ? "95%" : 800}
                centered
            >
                <List
                    key={personelListKey}
                    dataSource={personelData}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar icon={<UserOutlined />} />}
                                title={`${item.name} ${item.surname}`}
                                description={
                                    <Space direction="vertical">
                                        <Text>Başlangıç: {item.startDate ? dayjs(item.startDate).format('DD.MM.YYYY') : '-'}</Text>
                                        <Text>Bitiş: {item.endDate ? dayjs(item.endDate).format('DD.MM.YYYY') : '-'}</Text>
                                    </Space>
                                }
                            />
                        </List.Item>
                    )}
                    pagination={{
                        onChange: page => {
                            setPage(page);
                        },
                        pageSize: 5,
                        position: 'bottom',
                        align: 'center',
                    }}
                />
            </Modal>

            {/* Mağaza Listesi Modal - en sona ekleyin */}
            <Modal
                title={
                    <Space>
                        <ShopOutlined />
                        <span>Mağaza Listesi</span>
                        <Badge count={generalInfo.totalCompany} style={{ backgroundColor: '#52c41a' }} />
                    </Space>
                }
                open={storeModalVisible}
                onCancel={() => setStoreModalVisible(false)}
                footer={null}
                width={800}
            >
                {/* Mevcut Mağaza Listesi'ni kullanıyoruz */}
                <List
                    dataSource={generalInfo.companies}
                    loading={loading}
                    pagination={{
                        pageSize: 6,
                        size: 'small',
                        total: generalInfo.companies?.length,
                    }}
                    renderItem={store => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        icon={<ShopOutlined />}
                                        style={{ backgroundColor: '#1890ff' }}
                                    />
                                }
                                title={store.name}
                                description={`Şehir: ${store.country}`}
                            />
                        </List.Item>
                    )}
                />
            </Modal>

            <Modal
                title="Toplantı Oluştur"
                open={meetModalVisible}
                onCancel={() => {
                    setMeetModalVisible(false);
                    setMeetRoom(null);
                    setMeetJwt(null);
                }}
                footer={null}
                width={1000}
                destroyOnClose
            >
                {!meetRoom ? (
                    <Form
                        form={meetForm}
                        layout="vertical"
                        onFinish={handleMeetCreate}
                    >
                        <Form.Item
                            name="roomName"
                            label="Toplantı Adı"
                            rules={[{ required: true, message: 'Lütfen toplantı adını giriniz!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="moderator"
                            label="Moderatör Olarak Katıl"
                            initialValue="false"
                        >
                            <Select>
                                <Option value="true">Evet</Option>
                                <Option value="false">Hayır</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="record"
                            label="Video kaydı alınsın"
                            initialValue="false"
                        >
                            <Select>
                                <Option value="true">Evet</Option>
                                <Option value="false">Hayır</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={meetLoading}>
                                Toplantı Oluştur
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <div style={{ 
                        height: '600px',
                        width: '100%',
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        overflow: 'hidden'
                    }}>
                        <JitsiMeet 
                            key={meetRoom} 
                            roomName={meetRoom}
                            jwt={meetJwt}
                            onClose={() => {
                                handleMeetClose(meetRoom);
                                setMeetRoom(null);
                                setMeetJwt(null);
                            }}
                        />
                    </div>
                )}
            </Modal>
        </Layout>
    );
};

export default Dashboard;
