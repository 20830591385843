import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Tabs,
    Card,
    Row,
    Col,
    Timeline,
    Progress,
    message,
    Spin,
    Input,
    Select,
    Space,
    Button,
    Badge,
    Divider,
    Typography,
    Statistic,
    Tooltip,
    Modal,
    Table
} from 'antd';
import dayjs from "dayjs";
import {
    FileOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
    ReloadOutlined,
    GlobalOutlined,
    UserOutlined,
    EnvironmentOutlined,
    CalendarOutlined,
    ShopOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

import PersonelBazlı from './components/PersonelBazlı';
import KurumBazlı from './components/KurumBazlı';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;

const CompanyDetailPage = ({ companyId }) => {
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [tabKey, setTabKey] = useState("1");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [fileStatus, setFileStatus] = useState({
        total: 0,
        approved: 0,
        rejected: 0,
        timeout: 0
    });
    const [formCounts, setFormCounts] = useState({
        company: 0,
        personnel: 0,
        total: 0
    });
    const [isPersonelModalVisible, setIsPersonelModalVisible] = useState(false);
    const [personelCount, setPersonelCount] = useState(0);
    const [personelList, setPersonelList] = useState([]);
    const [personelLoading, setPersonelLoading] = useState(false);
    const [tabStats, setTabStats] = useState({
        total: 0,
        approved: 0,
        rejected: 0
    });

    const user = JSON.parse(localStorage.authdata).username; // Operator için kullanıcı bilgisini al

    // Şirket detaylarını getir
    const fetchCompanyDetails = async () => {
        if (!companyId) return;
        
        try {
            const response = await axios.post('/findByCompany', {
                method: "findByCompany",
                processor: "company",
                data: { 
                    id: companyId,
                    operator: user // operator bilgisini ekleyelim
                }
            });

            if (response.data && response.data.data) {
                const companyData = response.data.data;
                setCompanyData({
                    name: companyData.name,
                    createDate: companyData.createDate,
                    country: companyData.country,
                    communicationPersonnel: companyData.communicationPersonnel,
                    address: companyData.address
                });
            }
        } catch (error) {
            console.error("Şirket bilgileri alınamadı:", error);
            message.error("Şirket bilgileri alınamadı");
        }
    };

    // Dosya verilerini getir
    const fetchFileData = async () => {
        if (!companyId) return;
        
        setLoading(true);
        try {
            const response = await axios.post('/findFilesCompanyPersonel', {
                method: "findFilesCompanyPersonel",
                processor: "file",
                data: { id: companyId }
            });

            if (response.data && response.data.data && response.data.data.personelFileList) {
                const personelFileList = response.data.data.personelFileList;
                
                // Personel bazlı dosyaları düzgün şekilde say
                const personnelFiles = [];
                const companyFiles = [];

                // Her personel için dosyaları kontrol et
                Object.keys(personelFileList).forEach(personel => {
                    const files = personelFileList[personel];
                    if (Array.isArray(files)) {
                        files.forEach(file => {
                            if (personel) {
                                personnelFiles.push({ ...file, personel });
                            } else {
                                companyFiles.push(file);
                            }
                        });
                    }
                });

                // Form sayılarını güncelle
                const newFormCounts = {
                    company: companyFiles.length,
                    personnel: personnelFiles.length,
                    total: companyFiles.length + personnelFiles.length
                };
                setFormCounts(newFormCounts);

                // İstatistikleri hesapla ve güncelle
                const stats = calculateStats(tabKey === "1" ? companyFiles : personnelFiles);
                setTabStats(stats);
            }
        } catch (error) {
            console.error("Dosya bilgileri alınamadı:", error);
            if (error.response) {
                message.error(`Dosya bilgileri alınamadı: ${error.response.data?.message || 'Sunucu hatası'}`);
            } else if (error.request) {
                message.error("Sunucuya bağlanılamadı. Lütfen internet bağlantınızı kontrol edin.");
            } else {
                message.error("Dosya bilgileri alınamadı: " + error.message);
            }
        } finally {
            setLoading(false);
        }
    };

    // İstatistikleri hesapla
    const calculateStats = (data) => {
        if (!Array.isArray(data)) return {
            total: 0,
            approved: 0,
            rejected: 0
        };

        return {
            total: data.length,
            approved: data.filter(item => item.approved === true).length,
            rejected: data.filter(item => item.reject === true).length
        };
    };

    // Personel listesini getir
    const fetchPersonelList = async () => {
        if (!companyId) return;

        setPersonelLoading(true);
        try {
            const response = await axios.post('/findPersonelListWithCompany', {
                method: "findPersonelListWithCompany",
                processor: "personelManagement",
                data: { 
                    id: companyId,
                    operator: user // operator bilgisini ekle
                }
            });

            if (response.data && response.data.data) {
                setPersonelList(response.data.data);
                setPersonelCount(response.data.data.length);
            }
        } catch (error) {
            console.error("Personel listesi alınamadı:", error);
        } finally {
            setPersonelLoading(false);
        }
    };

    // Modal için sütun tanımları
    const personelColumns = [
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            width: 120,
        },
        {
            title: 'Soyad',
            dataIndex: 'surname',
            key: 'surname',
            width: 120,
        },
        {
            title: 'Telefon',
            dataIndex: 'telefon',
            key: 'telefon',
            width: 120,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Başlangıç Tarihi',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 120,
            render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : '-'
        },
        {
            title: 'Bitiş Tarihi',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 120,
            render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : '-'
        }
    ];

    // Personel butonuna tıklandığında sadece modalı aç
    const handlePersonelClick = () => {
        setIsPersonelModalVisible(true);
    };

    // Component mount olduğunda verileri çek
    useEffect(() => {
        if (companyId) {
            fetchCompanyDetails();
            fetchFileData();
            fetchPersonelList();
        }
    }, [companyId]);

    // Tab değişikliğinde istatistikleri güncelle
    const handleTabChange = (key) => {
        setTabKey(key);
    };

    // Responsive tasarım için
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (loading) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                minHeight: '200px' 
            }}>
                <Spin size="large" tip="Veriler yükleniyor..." />
            </div>
        );
    }

    if (!companyData) {
        return <div>No company data available.</div>;
    }

    return (
        <div className="company-detail-page">
            {/* Şirket Bilgileri Kartı */}
            <Card 
                className="company-info-card"
                style={{ marginBottom: 24 }}
            >
                <Row gutter={[24, 24]}>
                    <Col xs={24} md={8}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <div>
                                <Title level={4} style={{ margin: 0 }}>
                                    {companyData?.name || 'Yükleniyor...'}
                                </Title>
                                <Text type="secondary">
                                    <CalendarOutlined style={{ marginRight: 8 }} />
                                    {companyData?.createDate ? 
                                        dayjs(companyData.createDate).format('DD.MM.YYYY HH:mm') : '-'}
                                </Text>
                            </div>
                            <div>
                                <Text>
                                    <GlobalOutlined style={{ marginRight: 8, color: '#1890ff' }} />
                                    <Text strong>Ülke:</Text> {companyData?.country || '-'}
                                </Text>
                            </div>
                        </Space>
                    </Col>

                    <Col xs={24} md={8}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Text>
                                <UserOutlined style={{ marginRight: 8, color: '#52c41a' }} />
                                <Text strong>İletişim Personeli:</Text> {companyData?.communicationPersonnel || '-'}
                            </Text>
                            <div>
                                <Button 
                                    type="primary"
                                    icon={<UserOutlined />}
                                    onClick={handlePersonelClick}
                                    style={{ width: '100%' }}
                                    loading={personelLoading}
                                >
                                    Personel Listesi ({personelCount})
                                </Button>
                            </div>
                        </Space>
                    </Col>

                    <Col xs={24} md={8}>
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Text>
                                <EnvironmentOutlined style={{ marginRight: 8, color: '#faad14' }} />
                                <Text strong>Adres:</Text>
                            </Text>
                            <div style={{ 
                                marginLeft: 24,
                                whiteSpace: 'pre-wrap',
                                color: 'rgba(0, 0, 0, 0.65)'
                            }}>
                                {companyData?.address || '-'}
                            </div>
                        </Space>
                    </Col>
                </Row>
            </Card>

            {/* Personel Listesi Modal */}
            <Modal
                title={
                    <Space>
                        <UserOutlined />
                        <span>Personel Listesi</span>
                        <Badge count={personelCount} showZero color="#1890ff" />
                    </Space>
                }
                open={isPersonelModalVisible}
                onCancel={() => setIsPersonelModalVisible(false)}
                width={1200}
                footer={[
                    <Button key="back" onClick={() => setIsPersonelModalVisible(false)}>
                        Kapat
                    </Button>
                ]}
            >
                <Table
                    columns={personelColumns}
                    dataSource={personelList}
                    loading={personelLoading}
                    rowKey="id"
                    scroll={{ x: 1100, y: 400 }}
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total) => `Toplam ${total} personel`,
                        showQuickJumper: true
                    }}
                    size="middle"
                    bordered
                    rowClassName={() => 'custom-row'}
                />
                <style jsx>{`
                    .custom-row:hover {
                        background-color: #f5f5f5;
                    }
                    .ant-table-cell {
                        white-space: nowrap;
                    }
                `}</style>
            </Modal>

            <Card
                
            >
                {/* Tabs */}
                <Tabs 
                    activeKey={tabKey}
                    onChange={handleTabChange}
                    type="card"
                    className="custom-tabs"
                    size={isMobile ? 'small' : 'middle'}
                >
                    <TabPane tab="Mağaza Bazlı" key="1">
                        <KurumBazlı 
                            companyId={companyId}
                            onDataLoaded={(data) => {
                                const stats = calculateStats(data);
                                setTabStats(stats);
                            }}
                        />
                    </TabPane>
                    <TabPane tab="Personel Bazlı" key="2">
                        <PersonelBazlı 
                            companyId={companyId}
                            onDataLoaded={(data) => {
                                const stats = calculateStats(data);
                                setTabStats(stats);
                            }}
                        />
                    </TabPane>
                </Tabs>
            </Card>

            <style jsx>{`
                .company-detail-page {
                    padding: ${isMobile ? '8px' : '24px'};
                }
                .custom-card {
                    background: #fff;
                    border-radius: 8px;
                }
                .custom-tabs {
                    margin-top: ${isMobile ? '12px' : '16px'};
                }
                .company-info-card {
                    box-shadow: 0 1px 2px rgba(0,0,0,0.03);
                    border-radius: 8px;
                }
                .info-section, .stats-section {
                    transition: all 0.3s ease;
                }
                .info-section:hover, .stats-section:hover {
                    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
                }
                @media (max-width: 768px) {
                    .company-info-card {
                        margin-bottom: 16px;
                    }
                    .ant-statistic {
                        text-align: center;
                    }
                }
                .stat-card {
                    border-radius: 8px;
                    transition: all 0.3s ease;
                }
                .stat-card:hover {
                    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
                }
                @media (max-width: 576px) {
                    .stat-card {
                        margin-bottom: 16px;
                    }
                }
            `}</style>
        </div>
    );
};

export default CompanyDetailPage;
