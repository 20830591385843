import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Modal, Form, Input, Switch, Divider, Row, Col, Tag, Popconfirm } from "antd";
import axios from "axios";
import { DeleteOutlined, EditOutlined, MailOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const MailDefinition = () => {
    const [selectionType] = useState("radio");
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [modal1Open, setModal1Open] = useState(false); // Kullanıcı ekleme/güncelleme modal
    const [modal3Open, setModal3Open] = useState(false); // Kullanıcı silme modal
    const [modal4Open, setModal4Open] = useState(false); // Test mail modal
    const [isUpdateMode, setIsUpdateMode] = useState(false); // Ek mi güncelleme mi belirlemek için
    const [form] = Form.useForm();
    const [testMailForm] = Form.useForm(); // Test mail formu
    const { Search } = Input;
    const user = JSON.parse(localStorage.authdata).username;

    const listData = {
        method: "emailDefinitionFindAll",
        processor: "emailDefinition",
        operator: user
    };

    // Kullanıcı listesini refresh etme
    const refreshData = () => {
        axios.post("/emailDefinitionFindAll", listData)
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("An error occurred while retrieving data. ", error);
            });
    };

    // Kullanıcı ekleme isteği
    const sendRequestToUserAdd = (data) => {
        const requestData = {
            host: data.host ? data.host : null,
            port: data.port ? data.port : null,
            mail: data.mail ? data.mail : null,
            password: data.password ? data.password : null,
            auth: data.auth ? data.auth : null,
            tls: data.startTlsEnable ? data.startTlsEnable : null,
            operator: user
        };

        axios.post("/emailDefinitionAdd", { method: "emailDefinitionAdd", processor: "emailDefinition", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal1Open(false);
                    successMessage();
                    refreshData();
                } else {
                    setModal1Open(false);
                    errorMessage();
                }
            })
            .catch(() => {
                setModal1Open(false);
                errorMessage();
            });
    };

    // Kullanıcı güncelleme isteği
    const sendRequestToUserUpdate = (data) => {
        const requestData = {
            id: selectedRow.id, // Güncellenecek kullanıcının ID'si
            host: data.host ? data.host : null,
            port: data.port ? data.port : null,
            mail: data.mail ? data.mail : null,
            password: data.password ? data.password : null,
            auth: data.auth ? data.auth : null,
            tls: data.startTlsEnable ? data.startTlsEnable : null,
            operator: user
        };

        axios.post("/emailDefinitionUpdate", { method: "emailDefinitionUpdate", processor: "emailDefinition", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal1Open(false);
                    successMessage();
                    refreshData();
                } else {
                    setModal1Open(false);
                    errorMessage();
                }
            })
            .catch(() => {
                setModal1Open(false);
                errorMessage();
            });
    };

    // Test mail gönderme isteği
    const sendRequestForTestMail = (values) => {
        const requestData = {
            mail: values.email,  // Modal'dan gelen maili kullanıyoruz
            operator: user,
        };

        axios.post("/testMail", { method: "testMail", processor: "mainDashboard", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal4Open(false);
                    successMessageTestMail();
                    refreshData();
                } else {
                    setModal4Open(false);
                    errorMessageTestMail();
                }
            })
            .catch(() => {
                setModal4Open(false);
                errorMessageTestMail();
            });
    };

    // Test mail modalı OK butonuna basıldığında
    const handleTestMailOk = () => {
        testMailForm.validateFields()
            .then((values) => {
                sendRequestForTestMail(values); // Test mail gönderme isteği
                testMailForm.resetFields(); // Formu sıfırla
            })
            .catch((errorInfo) => {
                console.error('Form validation error:', errorInfo);
            });
    };

    // Başarı ve hata mesajları
    const successMessage = () => {
        message.success('Operation completed successfully.');
    };

    const errorMessage = () => {
        message.error('An error occurred while processing the operation.');
    };

    const successMessageTestMail = () => {
        message.success('Test mail sent successfully.');
    };

    const errorMessageTestMail = () => {
        message.error('An error occurred while sending the test mail.');
    };

    // Kullanıcı ekleme butonu tıklandığında
    const handleUserAddClick = () => {
        form.resetFields(); // Formu sıfırla
        setIsUpdateMode(false); // Ekleme modu
        setModal1Open(true); // Modalı aç
    };

    // Kullanıcı güncelleme butonu tıklandığında
    const handleUserUpdateClick = (record) => {
        setSelectedRow(record);
        form.setFieldsValue({
            host: record.mailSmtpHost,
            port: record.mailSmtpPort,
            mail: record.mailSmtpMail,
            password: record.mailSmtpPassword,
            auth: record.mailSmtpAuth,
            startTlsEnable: record.mailSmtpStarttlsEnable
        });
        setIsUpdateMode(true);
        setModal1Open(true);
    };

    const handleTestMailDeleteClick = (record) => {
        setSelectedRow(record);
        setModal3Open(true)
    }

    const handleTestMailClick = (record) => {
        setSelectedRow(record);
        setModal4Open(true);
    };

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                if (isUpdateMode) {
                    sendRequestToUserUpdate(values); // Güncelleme isteği
                } else {
                    sendRequestToUserAdd(values); // Ekleme isteği
                }
            })
            .catch((errorInfo) => {
                console.error('Form validation error:', errorInfo);
            });
    };

    useEffect(() => {
        refreshData();
    }, [token]);

    // Tablo kolonları
    const columns = [
        {
            title: "SMTP Sunucu",
            dataIndex: "mailSmtpHost",
            ellipsis: true,
            render: (host) => (
                <Space>
                    <MailOutlined />
                    <span>{host}</span>
                </Space>
            ),
            sorter: (a, b) => a.mailSmtpHost.localeCompare(b.mailSmtpHost),
            filters: tableData.map(item => ({ text: item.mailSmtpHost, value: item.mailSmtpHost })),
            onFilter: (value, record) => record.mailSmtpHost === value,
        },
        {
            title: "Port",
            dataIndex: "mailSmtpPort",
            width: 100,
            align: 'center',
            render: (port) => (
                <Tag color="blue">{port}</Tag>
            ),
        },
        {
            title: "Mail Adresi",
            dataIndex: "mailSmtpMail",
            ellipsis: true,
            render: (mail) => (
                <Space>
                    <MailOutlined />
                    <span>{mail}</span>
                </Space>
            ),
            sorter: (a, b) => a.mailSmtpMail.localeCompare(b.mailSmtpMail),
        },
        {
            title: "Şifre",
            dataIndex: "mailSmtpPassword",
            width: 120,
            render: () => (
                <Tag color="red">••••••••</Tag>
            ),
        },
        {
            title: "Kimlik Doğrulama",
            dataIndex: "mailSmtpAuth",
            width: 150,
            align: 'center',
            render: (auth) => (
                <Tag color={auth ? "green" : "red"}>
                    {auth ? "Aktif" : "Pasif"}
                </Tag>
            ),
            filters: [
                { text: 'Aktif', value: true },
                { text: 'Pasif', value: false },
            ],
            onFilter: (value, record) => record.mailSmtpAuth === value,
        },
        {
            title: "TLS Güvenliği",
            dataIndex: "mailSmtpStarttlsEnable",
            width: 150,
            align: 'center',
            render: (tls) => (
                <Tag color={tls ? "green" : "red"}>
                    {tls ? "Aktif" : "Pasif"}
                </Tag>
            ),
            filters: [
                { text: 'Aktif', value: true },
                { text: 'Pasif', value: false },
            ],
            onFilter: (value, record) => record.mailSmtpStarttlsEnable === value,
        },
        {
            title: 'İşlemler',
            key: 'action',
            width: 180,
            fixed: 'right',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Güncelle">
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => handleUserUpdateClick(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Sil">
                        <Popconfirm
                            title="Mail sunucusunu silmek istediğinize emin misiniz?"
                            onConfirm={() => handleTestMailDeleteClick(record)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                            />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title="Test Mail Gönder">
                        <Button
                            type="primary"
                            icon={<MailOutlined />}
                            onClick={() => handleTestMailClick(record)}
                            size="small"
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <>
            {/* Kullanıcı Ekleme/Güncelleme Modalı */}
            <Modal
                title={isUpdateMode ? "Mail Sunucusu Güncelle" : "Yeni Mail Sunucusu Ekle"}
                style={{ top: 20 }}
                open={modal1Open}
                onOk={handleOk}
                onCancel={() => {
                    setModal1Open(false);
                    form.resetFields();
                }}
                width={700}
                destroyOnClose
                centered
                maskClosable={false}
                okText={isUpdateMode ? "Güncelle" : "Ekle"}
                cancelText="İptal"
                bodyStyle={{ 
                    padding: '24px', 
                    maxHeight: '70vh', 
                    overflowY: 'auto' 
                }}
            >
                <Form 
                    form={form} 
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Divider orientation="left">Sunucu Bilgileri</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="host"
                                label="SMTP Sunucu Adresi"
                                rules={[{ required: true, message: 'Lütfen SMTP sunucu adresini giriniz!' }]}
                                tooltip="Örnek: smtp.gmail.com"
                            >
                                <Input placeholder="SMTP sunucu adresi" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="port"
                                label="SMTP Port"
                                rules={[{ required: true, message: 'Lütfen SMTP port numarasını giriniz!' }]}
                                tooltip="Örnek: 587"
                            >
                                <Input placeholder="SMTP port numarası" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">Mail Hesap Bilgileri</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="mail"
                                label="Mail Adresi"
                                rules={[
                                    { required: true, message: 'Lütfen mail adresini giriniz!' },
                                    { type: 'email', message: 'Geçerli bir mail adresi giriniz!' }
                                ]}
                            >
                                <Input placeholder="Mail adresi" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="password"
                                label="Mail Şifresi"
                                rules={[{ required: true, message: 'Lütfen mail şifresini giriniz!' }]}
                            >
                                <Input.Password placeholder="Mail şifresi" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider orientation="left">Güvenlik Ayarları</Divider>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="auth"
                                label="Kimlik Doğrulama"
                                valuePropName="checked"
                                tooltip="SMTP sunucusu için kimlik doğrulama gerekiyorsa aktif edin"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="startTlsEnable"
                                label="TLS Güvenliği"
                                valuePropName="checked"
                                tooltip="TLS güvenlik protokolünü kullanmak için aktif edin"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* Test Mail Modalı */}
            <Modal
                title="Test Mail Gönder"
                open={modal4Open}
                onOk={handleTestMailOk}
                onCancel={() => {
                    setModal4Open(false);
                    testMailForm.resetFields();
                }}
                width={500}
                destroyOnClose
                centered
                maskClosable={false}
                okText="Gönder"
                cancelText="İptal"
                bodyStyle={{ 
                    padding: '24px', 
                    maxHeight: '70vh', 
                    overflowY: 'auto' 
                }}
            >
                <Form 
                    form={testMailForm} 
                    layout="vertical"
                    requiredMark="optional"
                >
                    <Form.Item
                        name="email"
                        label="Test Mail Adresi"
                        rules={[
                            { required: true, message: 'Lütfen test mail adresini giriniz!' },
                            { type: 'email', message: 'Geçerli bir mail adresi giriniz!' }
                        ]}
                        tooltip="Test mailinin gönderileceği adres"
                    >
                        <Input placeholder="Test mail adresi" />
                    </Form.Item>
                </Form>
            </Modal>

            <div>
                <div style={{ 
                    textAlign: "left", 
                    display: "flex", 
                    alignItems: "center",
                    marginBottom: '16px',
                    gap: '8px'
                }}>
                    <Tooltip placement="rightTop" title="Yeni Mail Sunucusu Ekle">
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={handleUserAddClick}
                        >
                            Yeni Mail Sunucusu
                        </Button>
                    </Tooltip>
                </div>
                <Table
                    scroll={{ x: window.innerWidth < 768 ? 800 : "100%" }}
                    columns={columns}
                    dataSource={tableData.map((item) => ({ ...item, key: item.id }))}
                    tableLayout="fixed"
                    bordered
                    pagination={{
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Toplam ${total} kayıt`,
                    }}
                    onHeaderRow={(columns) => {
                        return {
                            style: { 
                                backgroundColor: '#f0f2f5',
                                fontSize: '14px',
                                fontWeight: 'bold'
                            }
                        };
                    }}
                    rowClassName={(record, index) => 
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                />
            </div>
        </>
    );
};

export default MailDefinition;
