import axios from 'axios'
import Login from "../pages/auth/Login";
import config from "../config";


const axiosSetup =  () => {
    axios.interceptors.request.clear();
    axios.interceptors.response.clear();

    axios.interceptors.request.use(function (requestConfig) {
        // Do something before request is sent;
        const cors = require('cors');
        const url = config.apiUrl;
        if (!requestConfig.url.includes("http://") ||!requestConfig.url.includes("https://")) {
            requestConfig.url = url + requestConfig.url;
        }

        const authdata = localStorage.getItem("authdata");
        
        if(authdata) {
            try {
                const authJson = JSON.parse(authdata);
                if (authJson && authJson.token) {
                    requestConfig.headers.Authorization = authJson.token;
                }
            } catch (error) {
                console.error("Token parse hatası:", error);
            }
        }

        return requestConfig;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

// Add a response interceptor
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {

        if (error.response?.data) {
            if(error.response.data.status==401){
                localStorage.removeItem("authdata");
                window.location.href = <Login/>;
            }
            return Promise.reject(error.response.data);
        } else if (error.response) {
            return Promise.reject(error.response);
        } else {
            return Promise.reject(error);
        }
    });
}

export default axiosSetup;
