import React, { useEffect, useState } from "react";
import { 
    Button, 
    Card, 
    Form, 
    Input, 
    Modal, 
    Select, 
    Space, 
    Table, 
    message, 
    Typography, 
    Tag, 
    Tooltip, 
    Popconfirm,
    Badge,
    Row,
    Col,
    Divider,
    Alert,
    Upload,
    Progress,
    Dropdown,
    Menu
} from 'antd';
import { 
    DeleteOutlined, 
    EyeOutlined, 
    UploadOutlined, 
    SearchOutlined,
    ReloadOutlined,
    FileOutlined,
    DownloadOutlined,
    InboxOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ClockCircleOutlined,
    MoreOutlined
} from '@ant-design/icons';
import axios from "axios";
import dayjs from 'dayjs';
import TextArea from "antd/es/input/TextArea";
import {auto} from "@popperjs/core";
import config from "../../config";

const { Dragger } = Upload;
const { Option } = Select;
const { Title, Text } = Typography;

const FileManagement = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('all');
    const [selectedFileType, setSelectedFileType] = useState('all');
    const [modal1Open, setModal1Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.authdata).username;
    let [rejectDescription, setRejectDescription] = useState("");
    const [personel, setPersonel] = useState(null);
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [previewModalVisible, setPreviewModalVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [searchText, setSearchText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        fetchCompanies();
        fetchFileTypes();
        fetchData();
        fetchCompanyList();
        fetchFileTypeList();
    }, [token]);

    useEffect(() => {
        if (!modal1Open && !modal3Open) {
            fetchData();
        }
    }, [modal1Open, modal3Open]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sendData = {
        username: user,
    };

    const fetchCompanies = () => {
        axios.post('/companyList', { method: "companyList", processor: "company" ,data:sendData})
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setCompanies([{ id: 'all', name: 'all' }, ...response.data.data]);
                }
            })
            .catch((error) => {
                console.error("Data çekilirken bir hata oluştu: ", error);
            });
    };

    const fetchFileTypes = () => {
        setFileTypes([
            { id: 'all', name: 'all' },
            { id: 'RISK_DEGERLENDIRME_RAPORU', name: 'Risk Değerlendirme Raporu' },
            { id: 'ACIL_DURUM_PLANI', name: 'Acil Durum Planı' },
            { id: 'ISG_UZMANI_ATAMA', name: 'ISG Uzmanı Atama' },
            { id: 'ISYERI_HEKIMI_ATAMA', name: 'İşyeri Hekimi Atama' },
            { id: 'ISVEREN_VEKILI_ATAMA', name: 'İşveren Vekili Atama' },
            { id: 'CALISAN_TEMSILCI_ATAMA', name: 'Çalışan Temsilci Atama' },
            { id: 'DESTEK_ELEMANI_ATAMA', name: 'Destek Elemanı Atama' },
            { id: 'YILLIK_DEGERLENDIRME_RAPORU', name: 'Yıllık Değerlendirme Raporu' },
            { id: 'ISG_EGITIM_RAPORU', name: 'İSG Eğitim Belgesi' },
            { id: 'PERIYODIK_SAGLIK_RAPORU', name: 'Periyodik Sağlık Raporu' },
            { id: 'TAHLIL_SONUCLARI', name: 'Tahlil Sonuçları' },
            { id: 'ISG_TAAHHUTNAME', name: 'İSG Taahhütname' },
            { id: 'SON_TEST', name: 'Son Test' },
            { id: 'ON_TEST', name: 'Ön Test' },
            { id: 'EGITIM_KATILIM_FORMU', name: 'Eğitim Katılım Formu' },
        ]);
    };

    const handlePersonelChange = (e) => {
        setPersonel(e.target.value);
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/fileList', { 
                method: "fileList", 
                processor: "fileManagement", 
                data: { username: JSON.parse(localStorage.authdata).username } 
            });
            if (response.data.data) {
                setData(response.data.data);
            }
        } catch (error) {
            console.error("An error occurred while fetching data: ", error);
            message.error("Veriler alınırken bir hata oluştu.");
        }
        setLoading(false);
    };

    const fetchCompanyList = async () => {
        try {
            const response = await axios.post('/companyList', { 
                method: "companyList", 
                processor: "company", 
                data: { username: JSON.parse(localStorage.authdata).username } 
            });
            if (response.data.data) {
                setCompanies(response.data.data);
            }
        } catch (error) {
            console.error("Mağaza listesi alınırken hata oluştu:", error);
        }
    };

    const fetchFileTypeList = async () => {
        try {
            const response = await axios.post('/fileTypeList', { 
                method: "fileTypeList", 
                processor: "fileTypeManagement", 
                data: {} 
            });
            if (response.data.data) {
                setFileTypes(response.data.data);
            }
        } catch (error) {
            console.error("Belge türü listesi alınırken hata oluştu:", error);
        }
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value);
    };

    const handleFileTypeChange = (value) => {
        setSelectedFileType(value);
    };

    const handlePersonel = (value) => {
        setPersonel(value);
    }
    const handleFilter = () => {
        fetchData();
    };

    const sendRequestForReject = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
            rejectDescription: rejectDescription,
        };

        setLoading(true);

        axios
            .post("/reject", { method: "reject", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Kayıt reddedildi.');
                    fetchData();
                } else {
                    message.error('Red işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the reject request: ", error);
                message.error('Red işlemi sırasında bir sorun oluştu');
            })
            .finally(() => {
                setLoading(false);
                resetDeleteInput(rejectDescription)
            });
    };

    const sendRequestForApproval = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/approved", { method: "approve", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Onaylama işlemi başarılı');
                    fetchData();
                } else {
                    message.error('Onay işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the approval request: ", error);
                message.error('Onay işlemi sırasında bir sorun oluştu.');
            })
            .finally(() => {
                setLoading(false);
                setApproveModalOpen(false);
            });
    };

    const sendRequestForDownload = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/download", { method: "download", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    const path = response.data.data.path;
                    const formattedPath = path.replace('/home/seziko/repo/', config.downloadUrl);
                    window.open(formattedPath, "_blank");
                    setModal3Open(false);
                    successMessage();
                } else {
                    errorMessage();
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the request: ", error);
                errorMessage();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDelete = async (record) => {
        try {
            await axios.post('/deleteFile', { 
                method: "deleteFile", 
                processor: "fileManagement", 
                data: { id: record.id, operator: JSON.parse(localStorage.authdata).username } 
            });
            message.success("Belge başarıyla silindi.");
            fetchData();
        } catch (error) {
            console.error("An error occurred while deleting file: ", error);
            message.error("Belge silinirken bir hata oluştu.");
        }
    };

    const handlePreview = (record) => {
        setSelectedFile(record);
        setPreviewUrl(`/api/viewFile?id=${record.id}`);
        setPreviewModalVisible(true);
    };

    const handleDownload = async (record) => {
        try {
            window.open(`/api/downloadFile?id=${record.id}`, '_blank');
        } catch (error) {
            console.error("An error occurred while downloading file: ", error);
            message.error("Belge indirilirken bir hata oluştu.");
        }
    };

    const handleUpload = async (options) => {
        const { file, onSuccess, onError, onProgress } = options;
        
        const formData = new FormData();
        formData.append('file', file);
        formData.append('companyId', form.getFieldValue('companyId'));
        formData.append('fileTypeId', form.getFieldValue('fileTypeId'));
        formData.append('description', form.getFieldValue('description') || '');
        formData.append('operator', JSON.parse(localStorage.authdata).username);
        
        try {
            const response = await axios.post('/uploadFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percent);
                    onProgress({ percent });
                },
            });
            
            onSuccess(response.data);
            message.success("Belge başarıyla yüklendi.");
            setModal1Open(false);
            form.resetFields();
            fetchData();
        } catch (error) {
            console.error("An error occurred while uploading file: ", error);
            message.error("Belge yüklenirken bir hata oluştu.");
            onError();
        }
    };

    const getStatusTag = (status) => {
        switch (status) {
            case 'Onaylandı':
                return <Tag icon={<CheckCircleOutlined />} color="success">{status}</Tag>;
            case 'Reddedildi':
                return <Tag icon={<CloseCircleOutlined />} color="error">{status}</Tag>;
            case 'Beklemede':
                return <Tag icon={<ClockCircleOutlined />} color="warning">{status}</Tag>;
            default:
                return <Tag color="default">{status}</Tag>;
        }
    };

    const getColumns = () => {
        const baseColumns = [
            {
                title: "Belge Adı",
                dataIndex: "fileName",
                sorter: (a, b) => a.fileName.localeCompare(b.fileName),
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        <Input
                            placeholder="Belge adı ara"
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => confirm()}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => confirm()}
                                icon={<SearchOutlined />}
                                size="small"
                            >
                                Ara
                            </Button>
                            <Button onClick={() => clearFilters()} size="small">
                                Temizle
                            </Button>
                        </Space>
                    </div>
                ),
                filterIcon: filtered => (
                    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
                ),
                onFilter: (value, record) =>
                    record.fileName.toLowerCase().includes(value.toLowerCase()),
                fixed: 'left',
                width: isMobile ? 120 : 200,
                render: (text, record) => (
                    <Space>
                        <FileOutlined />
                        <span>{text}</span>
                    </Space>
                ),
            },
            {
                title: "Belge Türü",
                dataIndex: ["fileType", "name"],
                filters: fileTypes.map(type => ({ text: type.name, value: type.name })),
                onFilter: (value, record) => record.fileType?.name === value,
                width: isMobile ? 120 : 150,
            },
            {
                title: "Mağaza",
                dataIndex: ["company", "name"],
                filters: companies.map(company => ({ text: company.name, value: company.name })),
                onFilter: (value, record) => record.company?.name === value,
                responsive: ['md'],
            },
            {
                title: "Yüklenme Tarihi",
                dataIndex: "uploadDate",
                render: (text) => text ? dayjs(text).format('DD.MM.YYYY HH:mm') : "-",
                sorter: (a, b) => dayjs(a.uploadDate).unix() - dayjs(b.uploadDate).unix(),
                responsive: ['lg'],
            },
            {
                title: "Durum",
                dataIndex: "status",
                render: (status) => getStatusTag(status),
                filters: [
                    { text: 'Onaylandı', value: 'Onaylandı' },
                    { text: 'Reddedildi', value: 'Reddedildi' },
                    { text: 'Beklemede', value: 'Beklemede' },
                ],
                onFilter: (value, record) => record.status === value,
            },
            {
                title: "Açıklama",
                dataIndex: "description",
                ellipsis: true,
                responsive: ['xl'],
            },
        ];

        if (isMobile) {
            baseColumns.push({
                title: "İşlem",
                key: "action",
                fixed: 'right',
                width: 60,
                render: (_, record) => (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: 'Görüntüle',
                                    icon: <EyeOutlined />,
                                    onClick: () => handlePreview(record)
                                },
                                {
                                    key: '2',
                                    label: 'İndir',
                                    icon: <DownloadOutlined />,
                                    onClick: () => handleDownload(record)
                                },
                                {
                                    key: '3',
                                    label: 'Sil',
                                    icon: <DeleteOutlined />,
                                    danger: true,
                                    onClick: () => {
                                        Modal.confirm({
                                            title: 'Silme Onayı',
                                            content: 'Belgeyi silmek istediğinize emin misiniz?',
                                            okText: 'Evet',
                                            cancelText: 'Hayır',
                                            onOk: () => handleDelete(record)
                                        });
                                    }
                                }
                            ]
                        }}
                        trigger={['click']}
                    >
                        <Button size="small" icon={<MoreOutlined />} />
                    </Dropdown>
                )
            });
        } else {
            baseColumns.push({
                title: "İşlemler",
                key: "action",
                fixed: 'right',
                width: 150,
                render: (_, record) => (
                    <Space size="middle">
                        <Tooltip title="Görüntüle">
                            <Button
                                type="primary"
                                icon={<EyeOutlined />}
                                onClick={() => handlePreview(record)}
                                size="small"
                            />
                        </Tooltip>
                        <Tooltip title="İndir">
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                onClick={() => handleDownload(record)}
                                size="small"
                            />
                        </Tooltip>
                        <Tooltip title="Sil">
                            <Popconfirm
                                title="Belgeyi silmek istediğinize emin misiniz?"
                                onConfirm={() => handleDelete(record)}
                                okText="Evet"
                                cancelText="Hayır"
                            >
                                <Button
                                    type="primary"
                                    danger
                                    icon={<DeleteOutlined />}
                                    size="small"
                                />
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            });
        }

        return baseColumns;
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    const onTableRowClick = (record) => {
        setSelectedRow(record);
    };

    const handleOk = () => {
        sendRequestForDownload();
        setSelectedRow(null);
    };

    const resetDeleteInput = () => {
        setRejectModalOpen(false)
        setRejectDescription(null)
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setModal3Open(false);
    };

    const successMessage = () => {
        message.success('Dosya indirildi.');
    };

    const errorMessage = () => {
        message.error('Dosya indirme işlemi sırasında bir sorun oluştu.');
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest: async ({ file, onSuccess, onError }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('operator', user);

            try {
                const response = await axios.post('/uploadTemp', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                onSuccess(response.data);
                message.success(`${file.name} Yükleme işlemi başarılı.`);
                fetchData();
            } catch (error) {
                onError(error);
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${file.name}.`);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                message.success(`${info.name} yükleme işlemi başarılı`);
                fetchData();
            } else if (status === 'error') {
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${info.name}.`);
            }
        },
        onDrop(e) {
        },
    };

    const rowClassName = (record) => {
        if (record.timeout) {
            return 'row-timeout';
        } else if (record.reject) {
            return 'row-reject';
        }else if(record.approved){
            return 'row-approved';
        }
        return '';
    };

    return (
        <>
            <Modal
                title="Ret Nedeni"
                open={rejectModalOpen}
                onOk={sendRequestForReject}
                onCancel={() => resetDeleteInput(rejectDescription)}
                confirmLoading={loading}
            >
                <p>Seçilen kayıt ret edilecektir. Onaylıyor musunuz?</p>
                <TextArea
                    value={rejectDescription}
                    onChange={(e) => setRejectDescription(e.target.value)}
                    rows={4}
                    placeholder="Ret nedenini yazınız."
                />
            </Modal>
            <Modal
                title="Dosya Onayı"
                open={approveModalOpen}
                onOk={sendRequestForApproval}
                onCancel={() => setApproveModalOpen(false)}
                confirmLoading={loading}
            >
                <p>Seçilen kaydı onaylanacaktır. Onaylıyormusunuz</p>
            </Modal>
            <Modal
                title="Seçilen kayıt indirilecektir. İndirmek istiyormusunuz?"
                open={modal3Open}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
            >
            </Modal>
            <Modal
                title="Dosya Yükle"
                visible={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
                footer={null}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                    </p>
                </Dragger>
            </Modal>
            <Card 
                className="custom-table-card"
                title={
                    <Space size="middle">
                        <Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                            Belge Yönetimi
                        </Title>
                        <Badge count={data.length} showZero color="#1890ff" />
                    </Space>
                }
                extra={
                    <Space>
                        <Button
                            type="primary"
                            icon={<UploadOutlined />}
                            onClick={() => {
                                form.resetFields();
                                setModal1Open(true);
                            }}
                        >
                            {!isMobile && "Yeni Belge Yükle"}
                        </Button>
                        <Tooltip title="Yenile">
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={fetchData}
                            />
                        </Tooltip>
                    </Space>
                }
            >
                <Table
                    className="custom-table"
                    columns={getColumns()}
                    dataSource={data}
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        ...tableParams.pagination,
                        showSizeChanger: !isMobile,
                        showQuickJumper: !isMobile,
                        showTotal: !isMobile ? (total) => `Toplam ${total} kayıt` : undefined,
                        size: isMobile ? 'small' : 'default',
                        pageSize: isMobile ? 5 : 10,
                    }}
                    onChange={(pagination, filters, sorter) => {
                        setTableParams({
                            pagination,
                            filters,
                            ...sorter,
                        });
                    }}
                    scroll={{ x: isMobile ? 600 : 1200, y: 500 }}
                    rowSelection={!isMobile ? {
                        type: 'checkbox',
                        onChange: (_, selectedRows) => setSelectedRows(selectedRows),
                    } : undefined}
                    size={isMobile ? "small" : "middle"}
                />
            </Card>
            <Modal
                title={selectedFile?.fileName}
                open={previewModalVisible}
                onCancel={() => setPreviewModalVisible(false)}
                footer={[
                    <Button key="download" type="primary" icon={<DownloadOutlined />} onClick={() => handleDownload(selectedFile)}>
                        İndir
                    </Button>,
                    <Button key="close" onClick={() => setPreviewModalVisible(false)}>
                        Kapat
                    </Button>
                ]}
                width={isMobile ? "95%" : 800}
                centered
                bodyStyle={{ height: '70vh', padding: 0 }}
            >
                <iframe 
                    src={previewUrl} 
                    style={{ width: '100%', height: '100%', border: 'none' }} 
                    title="Belge Önizleme"
                />
            </Modal>
        </>
    );
};

export default FileManagement;
