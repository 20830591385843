import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Input, Modal, Tag, Card, Typography, Badge, Dropdown, Tabs } from "antd";
import { 
    CheckCircleOutlined, 
    ClockCircleOutlined, 
    CloseCircleOutlined, 
    QuestionCircleOutlined,
    UploadOutlined, 
    SearchOutlined, 
    DownloadOutlined, 
    EyeOutlined, 
    MoreOutlined, 
    ReloadOutlined,
    CheckOutlined,
    StopOutlined
} from "@ant-design/icons";
import axios from "axios";
import dayjs from 'dayjs';
import FileUploadList from "./FileUploadList";
import config from "../../config";
import KurumBazlı from "./components/KurumBazlı";
import PersonelBazlı from "./components/PersonelBazlı";

const { TabPane } = Tabs;

const FileManagementUser = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [personel, setPersonel] = useState(null);

    const [selectedCompany, setSelectedCompany] = useState('all');
    const [selectedFileType, setSelectedFileType] = useState('all');

    const [modal3Open, setModal3Open] = useState(false);
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalKey, setModalKey] = useState(0);
    const user = JSON.parse(localStorage.authdata).username;

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [searchText, setSearchText] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: isMobile ? 5 : 10,
            showSizeChanger: !isMobile,
            showQuickJumper: !isMobile,
        },
    });

    useEffect(() => {
        fetchCompanies();
        fetchData();
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [token]);

    const fetchCompanies = () => {
        axios.post('/companyList', { method: "companyList", processor: "company", data: { username: user } })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setCompanies([{ id: 'all', name: 'all' }, ...response.data.data]);
                }
            })
            .catch((error) => {
                console.error("An error occurred while fetching company data: ", error);
            });
    };

    const fetchData = () => {
        axios.post('/getFiles', { method: "getFiles", processor: "files", data: { username: user } })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch(error => {
                console.error('Dosya listesi alınırken hata oluştu:', error);
            });
    };

    const sendRequestForDownload = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/download", { method: "download", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    debugger
                    const path = response.data.data.data.path;
                    const downloadUrl = config.repoUrl;
                    //const downloadUrl = "http://89.252.142.4/home/sehoffice/repo/";
                    window.open(downloadUrl, "_blank");
                    setModal3Open(false);
                    successMessage();
                } else {
                    errorMessage();
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the request: ", error);
                errorMessage();
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const getStatusTag = (status) => {
        switch (status) {
            case 'APPROVED':
                return <Tag icon={<CheckOutlined />} color="success">Onaylandı</Tag>;
            case 'REJECTED':
                return <Tag icon={<StopOutlined />} color="error">Reddedildi</Tag>;
            case 'PENDING':
                return <Tag icon={<ClockCircleOutlined />} color="warning">Beklemede</Tag>;
            case 'TIMEOUT':
                return <Tag icon={<ClockCircleOutlined />} color="default">Süresi Doldu</Tag>;
            default:
                return <Tag color="default">Bilinmiyor</Tag>;
        }
    };

    const columns = [
        {
            title: "Mağaza",
            dataIndex: ["company", "name"],
            width: isMobile ? 100 : 150,
        },
        {
            title: "Personel",
            dataIndex: "personel",
            width: isMobile ? 100 : 150,
        },
        {
            title: "Belge Tipi",
            dataIndex: "fileType",
            width: isMobile ? 100 : 150,
            render: (fileType, record) => {
                if(fileType === 'RISK_DEGERLENDIRME_RAPORU') {
                    return 'Risk Değerlendirme Raporu';
                } else if(fileType === 'ACIL_DURUM_PLANI'){
                    return 'Acil Durum Planı';
                } else if(fileType === 'ISG_UZMANI_ATAMA'){
                    return 'İş Sağlığı Güvenliği Uzmanı';
                } else if(fileType === 'ISYERI_HEKIMI_ATAMA'){
                    return 'İşyeri Hekimi Atama';
                } else if(fileType === 'TESPIT_ONERI_DEFTER'){
                    return 'Tespit Öneri Defter Nüshası';
                } else if(fileType === 'ISVEREN_VEKILI_ATAMA'){
                    return 'İşveren Vekili Atama';
                } else if(fileType === 'CALISAN_TEMSILCI_ATAMA'){
                    return 'Çalışan Temsilci Atama';
                } else if(fileType === 'DESTEK_ELEMANI_ATAMA'){
                    return 'Destek Elemanı Atama';
                } else if(fileType === 'YILLIK_CALISMA_PLANI'){
                    return 'Yıllık Çalışma Planı';
                } else if(fileType === 'YILLIK_DEGERLENDIRME_RAPORU'){
                    return 'Yıllık Değerlendirme Raporu';
                } else if(fileType === 'EGITIM_KATILIM_FORMU'){
                    return 'Eğitim Katılım Formu';
                } else if(fileType === 'ISG_EGITIM_RAPORU'){
                    return 'İSG Eğitim Belgesi';
                } else if(fileType === 'PERIYODIK_SAGLIK_RAPORU'){
                    return 'Periyodik Sağlık Raporu';
                } else if(fileType === 'TAHLIL_SONUCLARI'){
                    return 'Tahlil Sonuçları';
                } else if(fileType === 'ISG_TAAHHUTNAME'){
                    return 'İSG Taahhütname';
                } else if(fileType === 'SON_TEST'){
                    return 'Son Test';
                } else if(fileType === 'ON_TEST'){
                    return 'Ön Test';
                } else {
                    return fileType; // Bilinmeyen belge türleri için orijinal değeri göster
                }
            },
        },
        {
            title: "Yükleme Tarihi",
            dataIndex: "createDate",
            render: (text) => text ? dayjs(text).format('DD.MM.YYYY HH:mm') : "-",
            width: isMobile ? 120 : 150,
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: "startDate",
            render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : "-",
            width: isMobile ? 120 : 150,
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: "endDate",
            render: (text) => text ? dayjs(text).format('DD.MM.YYYY') : "-",
            width: isMobile ? 120 : 150,
        },
        {
            title: "Durum",
            key: "status",
            width: 120,
            render: (_, record) => {
                if (record.reject) {
                    return <Tag icon={<CloseCircleOutlined />} color="error">Reddedildi</Tag>;
                } else if (record.approved) {
                    return <Tag icon={<CheckCircleOutlined />} color="success">Onaylandı</Tag>;
                } else if (record.timeout) {
                    return <Tag icon={<ClockCircleOutlined />} color="default">Süresi Doldu</Tag>;
                } else {
                    return <Tag icon={<ClockCircleOutlined />} color="warning">Beklemede</Tag>;
                }
            },
            filters: [
                { text: 'Onaylandı', value: 'approved' },
                { text: 'Reddedildi', value: 'rejected' },
                { text: 'Beklemede', value: 'pending' },
                { text: 'Süresi Doldu', value: 'timeout' }
            ],
            onFilter: (value, record) => {
                switch(value) {
                    case 'approved':
                        return record.approved;
                    case 'rejected':
                        return record.reject;
                    case 'timeout':
                        return record.timeout;
                    case 'pending':
                        return !record.approved && !record.reject && !record.timeout;
                    default:
                        return true;
                }
            }
        },
        {
            title: "Ret Nedeni",
            dataIndex: "rejectDescription",
            width: isMobile ? 150 : 200,
            render: (text, record) => record.reject ? text || "-" : null,
            shouldCellRender: (record) => record.reject === true
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    const handleOk = () => {
        sendRequestForDownload();
        setSelectedRow(null);
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setModal3Open(false);
    };

    const successMessage = () => {
        message.success('Dosya indiriliyor.');
    };

    const errorMessage = () => {
        message.error('Dosya indirme işlemi sırasında bir hata oluştu');
    };

    const showUploadModal = () => {
        setModalKey(prevKey => prevKey + 1); // Reset the modal content by changing the key
        setUploadModalVisible(true);
    };

    const handleUploadModalCancel = () => {
        fetchData();
        setUploadModalVisible(false);
    };

    const handleDownload = async (record) => {
        try {
            const sendData = {
                "method": "download",
                "processor": "company",
                "data": {
                    "id": record.id
                }
            };

            const response = await axios.post('/download', sendData, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', record.fileName || 'document');
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);

        } catch (error) {
            console.error("Dosya indirme hatası:", error);
            message.error("Dosya indirme işlemi sırasında bir hata oluştu");
        }
    };

    return (
        <Card
            title={
                <Space>
                    <Typography.Title level={isMobile ? 5 : 4} style={{ margin: 0 }}>
                        Belgelerim
                    </Typography.Title>
                    <Badge count={tableData.length} showZero color="#1890ff" />
                </Space>
            }
            extra={
                <Space>
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={showUploadModal}
                    >
                        {!isMobile && "Belge Yükle"}
                    </Button>
                    <Tooltip title="Yenile">
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={fetchData}
                        />
                    </Tooltip>
                </Space>
            }
        >
            <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                scroll={{ x: isMobile ? 800 : 1200 }}
                pagination={{
                    ...tableParams.pagination,
                    total: tableData.length,
                    showTotal: (total) => !isMobile && `Toplam ${total} kayıt`,
                }}
                onChange={(pagination, filters, sorter) => {
                    setTableParams({
                        pagination,
                        filters,
                        ...sorter,
                    });
                }}
                size={isMobile ? "small" : "middle"}
                rowKey="id"
            />
            
            {/* File Upload Modal */}
            <Modal
                title="Belge Yükle"
                visible={uploadModalVisible}
                onCancel={handleUploadModalCancel}
                footer={null}
                width={800}
                key={modalKey}
            >
                <FileUploadList key={modalKey} />
            </Modal>
        </Card>
    );
};

export default FileManagementUser;
