import React, {Component, useEffect, useState} from 'react';
import './App.css';
import ISG from "./pages/ISG";
import { ContextProvider } from "./context";


const App = () =>{

    const [authdatalocal, setAuthdatalocal] = useState(null);
    const [isStorageLoaded, setStorageLoaded] = useState(false);

    useEffect(() => {
        const authStr = localStorage.getItem('authdata');
        if (authStr != null) {
            setAuthdatalocal(JSON.parse(authStr));
        }
        else {
            setAuthdatalocal({signedIn:false});
        }
    }, []);

    useEffect(() => {
        setStorageLoaded(true)
    },[authdatalocal])

        return (
            <>
            {isStorageLoaded === true ?
                    <ContextProvider>
                        <div className="App">
                            <ISG authData={authdatalocal}/>
                        </div>
                    </ContextProvider>
                    :
                    <span>loading</span>
            }
            </>
        );


}




export default App;
