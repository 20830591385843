import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {MainContext} from "../../context";

const ForgotPassword = () => {

    const navigate = useNavigate();

    const [value, dispatch] = useContext(MainContext);


    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [isLoggedIn, setIsLoggedin] = useState(false);


    const forgotClicked = (event) => {
        let status = ""
        if((null != username ) || (null != password)) {
            const data = {username: username, password: password, newPassword: newPassword};
            axios.post("/userForgotPassword", data).then((res) => {
                loginState(res)
            }).catch(error =>{
                setIsLoggedin(false);
            })
        }
    };

    function loginState(props) {
        localStorage.setItem('authdata', JSON.stringify({
            signedIn: true,
            username: username,
        }));

        dispatch({
            "type": "SIGN_IN", payload: {
                signedIn: true,
                username: username
            },
        });

        navigate("/")
    }

     return (
         <>
             <br/>
             <br/>
             <>
        <div className="login">
            <h1>Parolamı Unuttum</h1>
            <div className="container">
                User Name: <input type="text" name="username"
                                  onChange={(event) => setUsername(event.target.value)}/>
                Password: <input type="password" name="password"
                                 onChange={(event) => setPassword(event.target.value)}/>
                Repeat Password: <input type="password" name="newPassword"
                                 onChange={(event) => setNewPassword(event.target.value)}/>
                <br/>
                <br/>
                {isLoggedIn && <div>Başarılı Giriş</div>}
                {!isLoggedIn &&
                    <div className="alert alert-warning">Kullanıcı adı veya şifreyi kontrol ediniz.!</div>}
                <button className="btn btn-success" onClick={forgotClicked}>Değiştir</button>
                <button className="btn btn-success" onClick={() => navigate("/login")}>Logine Dön</button>
            </div>
        </div>
    </>
</>
    )
}

export default ForgotPassword;