import React, { useEffect, useState } from "react";
import {Table, Button, message, Space, Tooltip, Modal, Form, Input, Card, Select, Typography, Tag, Popconfirm, Badge, Row, Col, Divider, Alert} from "antd";
import axios from "axios";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, ReloadOutlined, ShopOutlined, GlobalOutlined, MailOutlined, PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import CompanyUpdate from "./Updates/CompanyUpdate";
import dayjs from "dayjs";

const { Option } = Select;
const { Title, Text } = Typography;
const { TextArea } = Input;

const CompanyDefinitionsList = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const [countries, setCountries] = useState([]);

    const user = JSON.parse(localStorage.authdata).username;

    const sendData = {
        username: user,
    };

    const loadTableData = () => {
        axios.post("/companyList", { method: "companyList", processor: "company" , data:sendData})
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Veri alınırken bir hata oluştu: ", error);
            });
    };

    useEffect(() => {
        loadTableData();
        fetchCountries();
    }, []);

    const onSearch = (searchValue) => {
        const searchData = {
            search: searchValue,
            operator: user,
        };

        axios.post("/companySearch", {
            method: "companySearch",
            processor: "company",
            data: searchData,
        })
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Veri alınırken bir hata oluştu: ", error);
            });
    };

    const sendRequestToCompanyAdd = (data) => {
        const requestData = {
            name: data.name,
            code: data.code,
            phone:data.phone,
            email:data.email,
            address:data.address,
            communicationPersonnel:data.communicationPersonnel,
            operator: user,
            country:data.country,
        };

        axios.post("/companyAdd", { method: "companyAdd", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal1Open(false);
                    message.success('Mağaza başarıyla eklendi.');
                    loadTableData();
                } else {
                    setModal1Open(false);
                    message.error('Mağaza eklenirken bir hata oluştu');
                }
            })
            .catch((error) => {
                setModal1Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const sendRequestToCompanyUpdate = (data) => {
        const requestData = {
            name: data.name,
            id: selectedRow.id,
            code : data.code,
            phone:data.phone,
            email:data.email,
            address:data.address,
            communicationPersonnel:data.communicationPersonnel,
            operator: user,
            country:data.country,
        };

        axios.post("/companyUpdate", { method: "companyUpdate", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal2Open(false);
                    message.success('Mağaza başarıyla güncellendi');
                    loadTableData();
                } else {
                    console.error("Mağaza güncellenirken bir hata oluştu:", response.data.error);
                    setModal2Open(false);
                    message.error('Mağaza güncellenirken bir hata oluştu');
                }
            })
            .catch((error) => {
                console.error("İstek gönderilirken bir hata oluştu: ", error);
                setModal2Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const sendRequestForDelete = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        axios.post("/companyDelete", { method: "companyDelete", processor: "company", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal3Open(false);
                    message.success('Mağaza başarıyla silindi.');
                    loadTableData();
                    setSelectedRow(null);
                } else {
                    console.error("Mağaza silinirken bir hata oluştu:", response.data.error);
                    setModal3Open(false);
                    message.error('Mağaza silinirken bir hata oluştu');
                }
            })
            .catch((error) => {
                console.error("İstek gönderilirken bir hata oluştu: ", error);
                setModal3Open(false);
                message.error('İstek gönderilirken bir hata oluştu');
            });
    };

    const fetchCountries = async () => {
        try {
            setCountries([
                { code: '01', name: 'Adana' },
                { code: '02', name: 'Adıyaman' },
                { code: '03', name: 'Afyonkarahisar' },
                { code: '04', name: 'Ağrı' },
                { code: '05', name: 'Amasya' },
                { code: '06', name: 'Ankara' },
                { code: '07', name: 'Antalya' },
                { code: '08', name: 'Artvin' },
                { code: '09', name: 'Aydın' },
                { code: '10', name: 'Balıkesir' },
                { code: '11', name: 'Bilecik' },
                { code: '12', name: 'Bingöl' },
                { code: '13', name: 'Bitlis' },
                { code: '14', name: 'Bolu' },
                { code: '15', name: 'Burdur' },
                { code: '16', name: 'Bursa' },
                { code: '17', name: 'Çanakkale' },
                { code: '18', name: 'Çankırı' },
                { code: '19', name: 'Çorum' },
                { code: '20', name: 'Denizli' },
                { code: '21', name: 'Diyarbakır' },
                { code: '22', name: 'Edirne' },
                { code: '23', name: 'Elazığ' },
                { code: '24', name: 'Erzincan' },
                { code: '25', name: 'Erzurum' },
                { code: '26', name: 'Eskişehir' },
                { code: '27', name: 'Gaziantep' },
                { code: '28', name: 'Giresun' },
                { code: '29', name: 'Gümüşhane' },
                { code: '30', name: 'Hakkari' },
                { code: '31', name: 'Hatay' },
                { code: '32', name: 'Isparta' },
                { code: '33', name: 'Mersin' },
                { code: '34', name: 'İstanbul' },
                { code: '35', name: 'İzmir' },
                { code: '36', name: 'Kars' },
                { code: '37', name: 'Kastamonu' },
                { code: '38', name: 'Kayseri' },
                { code: '39', name: 'Kırklareli' },
                { code: '40', name: 'Kırşehir' },
                { code: '41', name: 'Kocaeli' },
                { code: '42', name: 'Konya' },
                { code: '43', name: 'Kütahya' },
                { code: '44', name: 'Malatya' },
                { code: '45', name: 'Manisa' },
                { code: '46', name: 'Kahramanmaraş' },
                { code: '47', name: 'Mardin' },
                { code: '48', name: 'Muğla' },
                { code: '49', name: 'Muş' },
                { code: '50', name: 'Nevşehir' },
                { code: '51', name: 'Niğde' },
                { code: '52', name: 'Ordu' },
                { code: '53', name: 'Rize' },
                { code: '54', name: 'Sakarya' },
                { code: '55', name: 'Samsun' },
                { code: '56', name: 'Siirt' },
                { code: '57', name: 'Sinop' },
                { code: '58', name: 'Sivas' },
                { code: '59', name: 'Tekirdağ' },
                { code: '60', name: 'Tokat' },
                { code: '61', name: 'Trabzon' },
                { code: '62', name: 'Tunceli' },
                { code: '63', name: 'Şanlıurfa' },
                { code: '64', name: 'Uşak' },
                { code: '65', name: 'Van' },
                { code: '66', name: 'Yozgat' },
                { code: '67', name: 'Zonguldak' },
                { code: '68', name: 'Aksaray' },
                { code: '69', name: 'Bayburt' },
                { code: '70', name: 'Karaman' },
                { code: '71', name: 'Kırıkkale' },
                { code: '72', name: 'Batman' },
                { code: '73', name: 'Şırnak' },
                { code: '74', name: 'Bartın' },
                { code: '75', name: 'Ardahan' },
                { code: '76', name: 'Iğdır' },
                { code: '77', name: 'Yalova' },
                { code: '78', name: 'Karabük' },
                { code: '79', name: 'Kilis' },
                { code: '80', name: 'Osmaniye' },
                { code: '81', name: 'Düzce' }
            ]);
        } catch (error) {
            console.error("Şehir listesi alınırken hata oluştu:", error);
        }
    };

    const handleDelete = async (record) => {
        try {
            await axios.post('/companyDelete', { 
                method: "companyDelete", 
                processor: "companyManagement", 
                data: { id: record.id, operator: JSON.parse(localStorage.authdata).username } 
            });
            message.success("Mağaza başarıyla silindi.");
            loadTableData();
        } catch (error) {
            console.error("An error occurred while deleting company: ", error);
            message.error("Mağaza silinirken bir hata oluştu.");
        }
    };

    const handleUpdate = (record) => {
        setSelectedRow(record);
        form.setFieldsValue({
            name: record.name,
            country: record.country,
            address: record.address,
            email: record.email,
            phone: record.phone,
            communicationPersonnel: record.communicationPersonnel,
            code: record.code
        });
        setModal2Open(true);
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            
            if (selectedCompany) {
                // Mağaza güncelleme
                const requestData = {
                    ...values,
                    id: selectedCompany.id,
                    operator: JSON.parse(localStorage.authdata).username
                };

                await axios.post('/updateCompany', { 
                    method: "companyUpdate", 
                    processor: "companyManagement", 
                    data: requestData 
                });
                message.success("Mağaza başarıyla güncellendi.");
            } else {
                // Yeni mağaza ekleme
                const requestData = {
                    ...values,
                    operator: JSON.parse(localStorage.authdata).username
                };

                await axios.post('/addCompany', { 
                    method: "companyAdd", 
                    processor: "companyManagement", 
                    data: requestData 
                });
                message.success("Mağaza başarıyla eklendi.");
            }
            
            setModal2Open(false);
            form.resetFields();
            loadTableData();
        } catch (error) {
            console.error("An error occurred: ", error);
            message.error(selectedCompany 
                ? "Mağaza güncellenirken bir hata oluştu." 
                : "Mağaza eklenirken bir hata oluştu.");
        }
    };

    const columns = [
        {
            title: "Mağaza Adı",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Mağaza adı ara"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                        >
                            Ara
                        </Button>
                        <Button onClick={() => clearFilters()} size="small">
                            Temizle
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record.name.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: "Şehir",
            dataIndex: "country",
            filters: countries.map(country => ({ text: country.name, value: country.name })),
            onFilter: (value, record) => record.country === value,
            render: (country) => (
                <Space>
                    <GlobalOutlined />
                    <span>{country}</span>
                </Space>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            ellipsis: true,
            render: (email) => (
                <Space>
                    <MailOutlined />
                    <span>{email}</span>
                </Space>
            ),
        },
        {
            title: "Telefon",
            dataIndex: "phone",
            render: (phone) => (
                <Space>
                    <PhoneOutlined />
                    <span>{phone}</span>
                </Space>
            ),
        },
        {
            title: "Kayıt Tarihi",
            dataIndex: "createDate",
            render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm:ss'),
            sorter: (a, b) => dayjs(a.createDate).unix() - dayjs(b.createDate).unix(),
        },
        {
            title: "İşlemler",
            key: "action",
            fixed: 'right',
            width: 120,
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Düzenle">
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => handleUpdate(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Sil">
                        <Popconfirm
                            title="Mağazayı silmek istediğinize emin misiniz?"
                            onConfirm={() => handleDelete(record)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const rowSelection = {
        type: "checkbox",
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };

    return (
        <>
            <Modal
                title="Yeni Mağaza Ekle"
                style={{ top: 20 }}
                open={modal1Open}
                onOk={() => {
                    form.validateFields()
                        .then((values) => {
                            sendRequestToCompanyAdd(values);
                            form.resetFields();
                        })
                        .catch((errorInfo) => {
                            console.error('Form doğrulama hatası:', errorInfo);
                        });
                }}
                onCancel={() => {
                    setModal1Open(false);
                    form.resetFields();
                }}
                width={700}
                destroyOnClose
                centered
                maskClosable={false}
                okText="Ekle"
                cancelText="İptal"
                bodyStyle={{ 
                    padding: '24px', 
                    maxHeight: '70vh', 
                    overflowY: 'auto' 
                }}
            >
                <CompanyAdd form={form} countries={countries} />
            </Modal>

            <Modal
                title="Mağaza Güncelle"
                style={{ top: 20 }}
                open={selectedRow && modal2Open}
                onOk={handleModalOk}
                onCancel={() => {
                    setModal2Open(false);
                    form.resetFields();
                }}
                width={700}
                destroyOnClose
                centered
                maskClosable={false}
                okText="Güncelle"
                cancelText="İptal"
                bodyStyle={{ 
                    padding: '24px', 
                    maxHeight: '70vh', 
                    overflowY: 'auto' 
                }}
            >
                {selectedRow && (
                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            name: selectedRow.name,
                            country: selectedRow.country,
                            address: selectedRow.address,
                            email: selectedRow.email,
                            phone: selectedRow.phone,
                            communicationPersonnel: selectedRow.communicationPersonnel,
                            code: selectedRow.code
                        }}
                    >
                        <Divider orientation="left">Mağaza Bilgileri</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Mağaza Kodu" 
                                    name="code" 
                                    rules={[{ required: true, message: 'Lütfen mağaza kodunu giriniz!' }]}
                                >
                                    <Input placeholder="Mağaza kodu" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label="Mağaza Adı" 
                                    name="name" 
                                    rules={[{ required: true, message: 'Lütfen mağaza adını giriniz!' }]}
                                >
                                    <Input placeholder="Mağaza adı" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">İletişim Bilgileri</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Telefon" 
                                    name="phone" 
                                    rules={[{ required: true, message: 'Lütfen telefon numarasını giriniz!' }]}
                                >
                                    <Input placeholder="Telefon numarası" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label="E-posta" 
                                    name="email" 
                                    rules={[
                                        { required: true, message: 'Lütfen e-posta adresini giriniz!' },
                                        { type: 'email', message: 'Geçerli bir e-posta adresi giriniz!' }
                                    ]}
                                >
                                    <Input placeholder="E-posta adresi" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">Adres Bilgileri</Divider>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item 
                                    label="Adres" 
                                    name="address" 
                                    rules={[{ required: true, message: 'Lütfen adresi giriniz!' }]}
                                >
                                    <TextArea rows={3} placeholder="Adres" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item 
                                    label="Şehir" 
                                    name="country" 
                                    rules={[{ required: true, message: 'Lütfen şehir seçiniz!' }]}
                                >
                                    <Select placeholder="Şehir seçiniz">
                                        {countries.map(country => (
                                            <Option key={country.code} value={country.name}>
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Divider orientation="left">Yetkili Bilgileri</Divider>
                        <Row>
                            <Col span={24}>
                                <Form.Item 
                                    label="Yetkili Kişi" 
                                    name="communicationPersonnel" 
                                    rules={[{ required: true, message: 'Lütfen yetkili kişiyi giriniz!' }]}
                                >
                                    <Input placeholder="Yetkili kişi adı" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>

            <Modal title="Silmek istediğinize emin misiniz?" open={modal3Open} onOk={sendRequestForDelete} onCancel={() => setModal3Open(false)} />

            <div>
                <div style={{ textAlign: "left", display: "flex", alignItems: "center" }}>
                    <Tooltip placement="rightTop" title="Ekle">
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setSelectedCompany(null);
                                form.resetFields();
                                setModal1Open(true);
                            }}
                            style={{ marginRight: '5px' }}
                        >
                            Mağaza Ekle
                        </Button>
                    </Tooltip>
                </div>
                <br/>
                <Table
                    columns={columns}
                    dataSource={tableData.map((item) => ({ ...item, key: item.id }))}
                    pagination={{
                        ...tableParams.pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Toplam ${total} kayıt`,
                    }}
                    scroll={{ x: 1000, y: 500 }}
                    rowSelection={rowSelection}
                    loading={loading}
                    onChange={(pagination, filters, sorter) => {
                        setTableParams({
                            pagination,
                            filters,
                            ...sorter,
                        });
                    }}
                    tableLayout="fixed"
                    bordered
                    onHeaderRow={(columns) => {
                        return {
                            style: { backgroundColor: '#f0f2f5',  fontSize: '14px' }
                        };
                    }}
                    rowClassName={() => 'custom-row'}
                />
            </div>
        </>
    );
};

const CompanyAdd = ({ form, countries }) => {
    return (
        <Form 
            form={form} 
            layout="vertical"
            requiredMark="optional"
        >
            <Divider orientation="left">Mağaza Bilgileri</Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item 
                        label="Mağaza Kodu" 
                        name="code" 
                        rules={[{ required: true, message: 'Lütfen mağaza kodunu giriniz!' }]}
                    >
                        <Input placeholder="Mağaza kodu" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Mağaza Adı" 
                        name="name" 
                        rules={[{ required: true, message: 'Lütfen mağaza adını giriniz!' }]}
                    >
                        <Input placeholder="Mağaza adı" />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left">İletişim Bilgileri</Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item 
                        label="Telefon" 
                        name="phone" 
                        rules={[{ required: true, message: 'Lütfen telefon numarasını giriniz!' }]}
                    >
                        <Input placeholder="Telefon numarası" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="E-posta" 
                        name="email" 
                        rules={[
                            { required: true, message: 'Lütfen e-posta adresini giriniz!' },
                            { type: 'email', message: 'Geçerli bir e-posta adresi giriniz!' }
                        ]}
                    >
                        <Input placeholder="E-posta adresi" />
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left">Adres Bilgileri</Divider>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item 
                        label="Adres" 
                        name="address" 
                        rules={[{ required: true, message: 'Lütfen adresi giriniz!' }]}
                    >
                        <TextArea rows={3} placeholder="Adres" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item 
                        label="Şehir" 
                        name="country" 
                        rules={[{ required: true, message: 'Lütfen şehir seçiniz!' }]}
                    >
                        <Select placeholder="Şehir seçiniz">
                            {countries.map(country => (
                                <Option key={country.code} value={country.name}>
                                    {country.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Divider orientation="left">Yetkili Bilgileri</Divider>
            <Row>
                <Col span={24}>
                    <Form.Item 
                        label="Yetkili Kişi" 
                        name="communicationPersonnel" 
                        rules={[{ required: true, message: 'Lütfen yetkili kişiyi giriniz!' }]}
                    >
                        <Input placeholder="Yetkili kişi adı" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default CompanyDefinitionsList;
