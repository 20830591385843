import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Switch, Modal, Form, Input, Select, Card, Typography, Tag, Popconfirm, Badge, Row, Col, Divider, Alert } from "antd";
import axios from "axios";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined, SearchOutlined, ReloadOutlined, UserOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../../context';
import { useContext } from 'react';

const { Option } = Select;
const { Title, Text } = Typography;

const UserDefinitionsList = () => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [token] = useState(JSON.parse(localStorage.authdata).token);
    const [tableData, setTableData] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [form] = Form.useForm();
    const user = JSON.parse(localStorage.authdata).username;
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [originalCompanyId, setOriginalCompanyId] = useState(null);
    const [value, dispatch] = useContext(MainContext);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const listData = {
        method: "userList",
        processor: "user",
        operator: user
    };

    const sendData = {
        username: user,
    };

    // Şirket listesini fetch etme
    const fetchCompanyList = async () => {
        try {
            const response = await axios.post("/companyList", { 
                method: "companyList", 
                processor: "company", 
                data: sendData 
            });
            
            if (response.data && response.data.data) {
                setCompanyList(response.data.data);
            }
        } catch (error) {
            message.error("An error occurred while fetching company list.");
        }
    };

    const refreshData = () => {
        axios.post("/userList", listData)
            .then((response) => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setTableData(response.data.data);
                }
            })
            .catch((error) => {
                message.error("Kullanıcı listelenirken hata oluştu.");
            });
    };

    const handleDelete = async (record) => {
        try {
            await axios.post('/deleteUser', { method: "deleteUser", processor: "user", data: { id: record.id } });
            message.success("Kullanıcı başarıyla silindi.");
            refreshData();
        } catch (error) {
            message.error("Kullanıcı silinirken bir hata oluştu.");
        }
    };

    const handleUpdate = (record) => {
        setSelectedUser(record);
        
        // Orijinal şirket ID'sini sakla
        if (record.company && record.company.id) {
            setOriginalCompanyId(record.company.id);
        } else {
            setOriginalCompanyId(null);
        }
        
        // Form alanlarını kullanıcının mevcut değerleriyle doldur
        form.setFieldsValue({
            username: record.username,
            email: record.email || '',
            mailActive: record.mailActive ? "true" : "false",
            userRole: record.userRole || 'ROLE_VIEWER',
            companyIdList: record.company ? record.company.id : null,
            isAdmin: record.userRole === 'ROLE_ADMIN'
        });

        setIsUpdateMode(true);
        setModalVisible(true);
    };

    const handleUserAddClick = () => {
        form.resetFields();
        setIsUpdateMode(false);
        setModalVisible(true);
    };

    const sendRequestToUserAdd = (data) => {
        const requestData = {
            username: data.username || null,
            password: data.password || null,
            isAdmin: data.isAdmin ? "ROLE_ADMIN" : "ROLE_VIEWER",
            //companyIdList: data.companyIdList || null,
            operator: user
        };

        axios.post("/userAdd", { method: "userAdd", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModalVisible(false);
                    successMessage();
                    refreshData();
                } else {
                    setModalVisible(false);
                    errorMessage();
                }
            })
            .catch(() => {
                setModalVisible(false);
                errorMessage();
            });
    };

    const sendRequestToUserUpdate = (data) => {
        const requestData = {
            username: data.username,
            id: selectedUser.id,
            password: data.password,
            companyIdList: data.companyIdList || null,
            operator: user,
        };
        axios.post("/userUpdate", { method: "userUpdate", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModalVisible(false);
                    successMessage();
                    refreshData();
                } else {
                    setModalVisible(false);
                    errorMessage();
                }
            })
            .catch(() => {
                setModalVisible(false);
                errorMessage();
            });
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            
            const selectedCompanyId = values.companyIdList || originalCompanyId;
            
            if (selectedUser) {
                // Kullanıcı güncelleme
                const requestData = {
                    username: values.username,
                    id: selectedUser.id,
                    email: values.email,
                    mailActive: values.mailActive,
                    userRole: values.userRole,
                    operator: JSON.parse(localStorage.authdata).username,
                    companyId: selectedCompanyId === undefined ? null : selectedCompanyId
                };

                // Şifre varsa ekle
                if (values.password && values.password.trim() !== '') {
                    requestData.password = values.password;
                }
                
                await axios.post('/userUpdate', {
                    method: "userUpdate", 
                    processor: "userManagement", 
                    data: requestData 
                });
                message.success("Kullanıcı başarıyla güncellendi.");
            } else {
                // Yeni kullanıcı ekleme
                const requestData = {
                    username: values.username,
                    password: values.password,
                    email: values.email,
                    mailActive: values.mailActive,
                    userRole: values.userRole,
                    operator: JSON.parse(localStorage.authdata).username,
                    companyId: selectedCompanyId === undefined ? null : selectedCompanyId
                };
                
                await axios.post('/userAdd', {
                    method: "userAdd", 
                    processor: "userManagement", 
                    data: requestData 
                });
                message.success("Kullanıcı başarıyla eklendi.");
            }
            
            setModalVisible(false);
            form.resetFields();
            fetchData();
        } catch (error) {
            message.error(selectedUser
                ? "Kullanıcı güncellenirken bir hata oluştu." 
                : "Kullanıcı eklenirken bir hata oluştu.");
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setModalVisible(false);
    };

    const handleDeleteOk = () => {
        sendRequestForDelete();
    };

    const sendRequestForDelete = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        axios.post("/userDelete", { method: "userDelete", processor: "userManagement", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    setModal3Open(false);
                    successMessage();
                    refreshData();
                } else {
                    setModal3Open(false);
                    errorMessage();
                }
            })
            .catch(() => {
                setModal3Open(false);
                errorMessage();
            });
    };

    const successMessage = () => {
        message.success('Operation successful.');
    };

    const errorMessage = () => {
        message.error('An error occurred.');
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.post('/userList', { method: "userList", processor: "user", data: {} });
            if (response.data.data) {
                setTableData(response.data.data);
            }
        } catch (error) {
            message.error("Veriler alınırken bir hata oluştu.");
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        fetchCompanyList();
    }, [token]);

    const columns = [
        {
            title: "Kullanıcı Adı",
            dataIndex: "username",
            sorter: (a, b) => a.username.localeCompare(b.username),
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder="Kullanıcı adı ara"
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                        >
                            Ara
                        </Button>
                        <Button onClick={() => clearFilters()} size="small">
                            Temizle
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => (
                <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
            ),
            onFilter: (value, record) =>
                record.username.toLowerCase().includes(value.toLowerCase()),
        },
        {
            title: "Rol",
            dataIndex: "userRole",
            render: (role) => (
                <Tag color={role === 'ROLE_ADMIN' ? 'red' : 'blue'}>
                    {role === 'ROLE_ADMIN' ? 'Admin' : 'User'}
                </Tag>
            ),
            filters: [
                { text: 'Admin', value: 'ROLE_ADMIN' },
                { text: 'User', value: 'ROLE_VIEWER' },
            ],
            onFilter: (value, record) => record.userRole === value,
        },
        {
            title: "Mail",
            dataIndex: "email"
        },
        {
            title: "Kayıt Tarihi",
            dataIndex: "createDate",
            render: (text) => dayjs(text).format('DD.MM.YYYY HH:mm:ss'),
            sorter: (a, b) => dayjs(a.createDate).unix() - dayjs(b.createDate).unix(),
        },
        {
            title: "İşlemler",
            key: "action",
            fixed: 'right',
            width: 120,
            render: (_, record) => (
                <Space size="middle">
                    <Tooltip title="Düzenle">
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => handleUpdate(record)}
                            size="small"
                        />
                    </Tooltip>
                    <Tooltip title="Sil">
                        <Popconfirm
                            title="Kullanıcıyı silmek istediğinize emin misiniz?"
                            onConfirm={() => handleDelete(record)}
                            okText="Evet"
                            cancelText="Hayır"
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                size="small"
                            />
                        </Popconfirm>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <>
            <Modal
                title={selectedUser ? "Kullanıcı Güncelle" : "Yeni Kullanıcı Ekle"}
                open={modalVisible}
                onOk={handleModalOk}
                onCancel={() => {
                    setModalVisible(false);
                    form.resetFields();
                }}
                width={700}
                destroyOnClose
                centered
                maskClosable={false}
                okText={selectedUser ? "Güncelle" : "Ekle"}
                cancelText="İptal"
                bodyStyle={{ padding: '24px', maxHeight: '70vh', overflowY: 'auto' }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        userRole: 'Viewer',
                        companyIdList: null
                    }}
                    requiredMark="optional"
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="username"
                                label="Kullanıcı Adı"
                                rules={[
                                    { required: true, message: 'Lütfen kullanıcı adını giriniz!' },
                                    { min: 3, message: 'Kullanıcı adı en az 3 karakter olmalıdır!' }
                                ]}
                                tooltip="Kullanıcının sisteme giriş yaparken kullanacağı ad"
                            >
                                <Input 
                                    prefix={<UserOutlined />} 
                                    placeholder="Kullanıcı adını giriniz" 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="password"
                                label="Şifre"
                                rules={[
                                    ...(selectedUser ? [] : [{ required: true, message: 'Lütfen şifre giriniz!' }]),
                                    { min: 1, message: 'Şifre en az 1 karakter olmalıdır!' }
                                ]}
                                tooltip={selectedUser ? 
                                    "Şifre değiştirmek istiyorsanız yeni şifre girin, değiştirmek istemiyorsanız boş bırakın" : 
                                    "Kullanıcının sisteme giriş yaparken kullanacağı şifre"
                                }
                            >
                                <Input.Password 
                                    prefix={<LockOutlined />}
                                    placeholder={selectedUser ? "Şifreyi değiştirmek için yeni şifre girin" : "Şifre giriniz"} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    // Zorunluluk kuralı kaldırıldı
                                    { type: 'email', message: 'Geçerli bir email adresi giriniz!' }
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="Email adresini giriniz (opsiyonel)"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="mailActive"
                                label="Email Gönderilsin"
                                rules={[{ required: true, message: 'Lütfen seçiniz!' }]}
                                tooltip="Kullanıcının personel eklendiğinde mail talep etme durumu"
                            >
                                <Select
                                    placeholder="Gönderim durumu seçiniz"
                                    optionLabelProp="label"
                                >
                                    <Option value="true" label="Evet">
                                        <Space>
                                            <Tag color="green">Evet</Tag>
                                        </Space>
                                    </Option>
                                    <Option value="false" label="Hayır">
                                        <Space>
                                            <Tag color="red">Hayır</Tag>
                                        </Space>
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <Divider orientation="left">Sistem Bilgileri</Divider>
                    
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="userRole"
                                label="Kullanıcı Rolü"
                                rules={[{ required: true, message: 'Lütfen rol seçiniz!' }]}
                                tooltip="Kullanıcının sistem içindeki yetki seviyesi"
                            >
                                <Select 
                                    placeholder="Rol seçiniz"
                                    optionLabelProp="label"
                                    onChange={(value) => {
                                        // Rolü değiştirdiğimizde form validasyonunu tetikle
                                        form.validateFields(['companyIdList']);
                                    }}
                                >
                                    <Option value="ROLE_ADMIN" label="Admin">
                                        <Space>
                                            <Tag color="red">Admin</Tag>
                                            Tam Yetkili Kullanıcı
                                        </Space>
                                    </Option>
                                    <Option value="ROLE_VIEWER" label="User">
                                        <Space>
                                            <Tag color="blue">User</Tag>
                                            Belge Yükleme Yetkisi
                                        </Space>
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="companyIdList"
                                label="Erişim Yetkisi Olan Kurum"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const userRole = getFieldValue('userRole');
                                            if (userRole === 'ROLE_ADMIN') {
                                                // Admin için zorunlu değil
                                                return Promise.resolve();
                                            } else if (!value && userRole === 'ROLE_VIEWER' && !originalCompanyId) {
                                                // User için zorunlu, ancak originalCompanyId varsa zorunlu değil
                                                return Promise.reject(new Error('Lütfen bir kurum seçiniz!'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                                tooltip="Kullanıcının erişim yetkisi olacağı kurum"
                            >
                                <div>
                                    <Select 
                                        placeholder="Kurum seçiniz"
                                        style={{ width: '100%' }}
                                        optionFilterProp="children"
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        allowClear
                                        onChange={(value) => {
                                            // Kullanıcı kurum seçtiğinde formu güncelle
                                            if (value === undefined) {
                                                // Kurum temizlendiğinde, orijinal değeri kullanacağız
                                                form.setFieldsValue({ companyIdList: null });
                                            }
                                        }}
                                    >
                                        {companyList.map(company => (
                                            <Option key={company.id} value={company.id}>
                                                {company.name}
                                            </Option>
                                        ))}
                                    </Select>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => 
                                        prevValues.userRole !== currentValues.userRole || 
                                        prevValues.companyIdList !== currentValues.companyIdList
                                    }>
                                        {({ getFieldValue }) => {
                                            const userRole = getFieldValue('userRole');
                                            const selectedCompany = getFieldValue('companyIdList');
                                            
                                            if (userRole === 'ROLE_ADMIN') {
                                                return (
                                                    <div style={{ marginTop: 4 }}>
                                                        <Text type="secondary" style={{ fontSize: '12px' }}>
                                                            Admin kullanıcıları için kurum seçimi zorunlu değildir.
                                                        </Text>
                                                    </div>
                                                );
                                            } else if (!selectedCompany && originalCompanyId && selectedUser) {
                                                // Eğer şirket seçilmemişse ve orijinal şirket varsa
                                                const originalCompany = companyList.find(company => company.id === originalCompanyId);
                                                if (originalCompany) {
                                                    return (
                                                        <div style={{ marginTop: 4 }}>
                                                            <Text type="secondary" style={{ fontSize: '12px' }}>
                                                                Mevcut kurum: {originalCompany.name}
                                                            </Text>
                                                        </div>
                                                    );
                                                }
                                            }
                                            return null;
                                        }}
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    
                </Form>
            </Modal>

            <Modal
                title="Delete User"
                open={modal3Open}
                onOk={handleDeleteOk}
                onCancel={() => setModal3Open(false)}
            >
                <p>Are you sure you want to delete selected user?</p>
            </Modal>

            <Card 
                className="custom-table-card"
                title={
                    <Space size="middle">
                        <Title level={4} style={{ margin: 0 }}>
                            Kullanıcı Listesi
                        </Title>
                        <Badge count={tableData.length} showZero color="#1890ff" />
                    </Space>
                }
                extra={
                    <Space>
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={handleUserAddClick}
                            style={{ marginRight: '5px' }}
                        >
                            Kullanıcı Ekle
                        </Button>
                        <Tooltip title="Yenile">
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={fetchData}
                            />
                        </Tooltip>
                    </Space>
                }
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    loading={loading}
                    rowKey={record => record.id}
                    pagination={{
                        ...tableParams.pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Toplam ${total} kayıt`,
                    }}
                    onChange={(pagination, filters, sorter) => {
                        setTableParams({
                            pagination,
                            filters,
                            ...sorter,
                        });
                    }}
                    scroll={{ x: 1000, y: 500 }}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (_, selectedRows) => setSelectedRows(selectedRows),
                    }}
                    size="middle"
                />
            </Card>
        </>
    );
};

export default UserDefinitionsList;
