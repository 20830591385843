import React, { useEffect, useState } from "react";
import { Table, Button, message, Space, Tooltip, Modal, Upload, Skeleton, Typography, Input, Tag } from "antd";
import { CheckOutlined, ClockCircleOutlined, CloseSquareOutlined, DownloadOutlined, FilterOutlined, InboxOutlined, InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import dayjs from 'dayjs';
import TextArea from "antd/es/input/TextArea";
import config from "../../../../../config";


const { Dragger } = Upload;
const { Text } = Typography;

// Belge türünü isim olarak döndüren yardımcı fonksiyon
const getDocumentTypeName = (fileType) => {
    if(fileType === 'ISG_EGITIM_RAPORU' ) {
        return 'İSG Eğitim Belgesi';
    } else if(fileType === 'PERIYODIK_SAGLIK_RAPORU'){
        return 'Periyodik Sağlık Raporu';
    } else if(fileType === 'TAHLIL_SONUCLARI'){
        return 'İkametgah Kaydı';
    } else if(fileType === 'ISG_TAAHHUTNAME'){
        return 'İSG Taahhütname';
    } else if(fileType === 'ON_TEST'){
        return 'Ön Test';
    } else if(fileType === 'SON_TEST'){
        return 'Son Test';
    }
    return fileType;
};

const FileManagement = (props) => {
    const [loading, setLoading] = useState(false);

    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);

    const [modal1Open, setModal1Open] = useState(false);
    const [modal3Open, setModal3Open] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);

    const [rejectDescription, setRejectDescription] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [searchText, setSearchText] = useState("");

    const user = JSON.parse(localStorage.authdata).username;
    const isAdmin = JSON.parse(localStorage.authdata).userRole;

    useEffect(() => {
        // Ekran boyutu değişikliklerini dinle
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        
        if (!modal1Open && !modal3Open) {
            fetchData();
        }
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [modal1Open, modal3Open, props.documentType]);

    const fetchData = () => {
        setLoading(true);

        const sendData = {
            "method":"findFileDetail",
            "processor":"company",
            "data":{
                "id" : props.companyId,
                "personel": props.personel,
                "fileType": props.documentType
            }
        }
        axios.post("/findFileDetail", sendData)
            .then((response) => {
                setLoading(false);
                if (response.data.data && Array.isArray(response.data.data)) {
                    let tableData = response.data.data;
                    setTableData(tableData);
                }
            })
            .catch((error) => {
                console.error("Data çekilirken bir sorun oluştu: ", error);
                setLoading(false);
            });
    };

    const sendRequestForReject = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
            rejectDescription: rejectDescription,
        };

        setLoading(true);

        axios
            .post("/reject", { method: "reject", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Kayıt reddedildi.');
                    fetchData();
                } else {
                    message.error('Red işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the reject request: ", error);
                message.error('Red işlemi sırasında bir sorun oluştu');
            })
            .finally(() => {
                setLoading(false);
                resetDeleteInput()
            });
    };

    const sendRequestForApproval = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/approved", { method: "approve", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    message.success('Onaylama işlemi başarılı');
                    fetchData();
                } else {
                    message.error('Onay işlemi sırasında bir sorun oluştu.');
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the approval request: ", error);
                message.error('Onay işlemi sırasında bir sorun oluştu.');
            })
            .finally(() => {
                setLoading(false);
                setApproveModalOpen(false);
            });
    };

    const sendRequestForDownload = () => {
        const requestData = {
            id: selectedRow.id,
            operator: user,
        };

        setLoading(true);

        axios
            .post("/download", { method: "download", processor: "files", data: requestData })
            .then((response) => {
                if (response.status === 200) {
                    const path = response.data.data.path;
                    //const formattedPath = path.replace('/home/seziko/repo/', config.downloadUrl);
                    const formattedPath = config.downloadUrl+path;
                    //const formattedPath = path.replace('/home/sehoffice/repo/', 'http://89.252.142.4/rest-api/download/');
                    window.open(formattedPath, "_blank");
                    setModal3Open(false);
                    successMessage();
                } else {
                    errorMessage();
                }
            })
            .catch((error) => {
                console.error("An error occurred while sending the request: ", error);
                errorMessage();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // Arama işlevi
    const handleSearch = (value) => {
        setSearchText(value);
    };

    // Filtrelenmiş veri
    const filteredData = tableData.filter(item => {
        const matchesSearch = (
            (item.personel && item.personel.toLowerCase().includes(searchText.toLowerCase())) ||
            (item.company && item.company.name && item.company.name.toLowerCase().includes(searchText.toLowerCase())) ||
            (getDocumentTypeName(item.fileType) && getDocumentTypeName(item.fileType).toLowerCase().includes(searchText.toLowerCase()))
        );
        
        return matchesSearch;
    });

    // Duruma göre renk ve simge belirleme
    const getStatusInfo = (record) => {
        if (record.reject === true) {
            return {
                icon: <CloseSquareOutlined />,
                color: 'red',
                text: 'Reddedildi'
            };
        } else if (record.approved === true) {
            return {
                icon: <CheckOutlined />,
                color: 'green',
                text: 'Onaylandı'
            };
        } else if (record.timeout) {
            return {
                icon: <ClockCircleOutlined />,
                color: 'orange',
                text: 'Süresi Geçmiş'
            };
        } else {
            return {
                icon: <InfoCircleOutlined />,
                color: 'blue',
                text: 'Bekliyor'
            };
        }
    };

    const columns = [
        {
            id: 1,
            title: "Durum",
            key: "status",
            width: isMobile ? 110 : 130,
            render: (_, record) => {
                const status = getStatusInfo(record);
                return (
                    <Tag color={status.color} icon={status.icon} style={{ whiteSpace: 'nowrap' }}>
                        {status.text}
                    </Tag>
                );
            },
            filters: [
                { text: 'Onaylandı', value: 'approved' },
                { text: 'Reddedildi', value: 'rejected' },
                { text: 'Süresi Geçmiş', value: 'timeout' },
                { text: 'Bekliyor', value: 'waiting' },
            ],
            onFilter: (value, record) => {
                if (value === 'approved') return record.approved === true;
                if (value === 'rejected') return record.reject === true;
                if (value === 'timeout') return record.timeout === true;
                if (value === 'waiting') return !record.approved && !record.reject;
                return true;
            },
        },
        {
            id: 3,
            title: "Belge Türü",
            dataIndex: "fileType",
            render: (fileType) => getDocumentTypeName(fileType),
            filters: [
                { text: 'İSG Eğitim Belgesi', value: 'ISG_EGITIM_RAPORU' },
                { text: 'Periyodik Sağlık Raporu', value: 'PERIYODIK_SAGLIK_RAPORU' },
                { text: 'İkametgah Kaydı', value: 'TAHLIL_SONUCLARI' },
                { text: 'İSG Taahhütname', value: 'ISG_TAAHHUTNAME' },
                { text: 'Ön Test', value: 'ON_TEST' },
                { text: 'Son Test', value: 'SON_TEST' },
            ],
            onFilter: (value, record) => record.fileType === value,
        },
        {
            id: 4,
            title: "Mağaza",
            dataIndex: "company",
            render: (company) => company ? company.name : "",
        },
        {
            id: 5,
            title: "Personel",
            dataIndex: "personel"
        },
        {
            id: 9,
            title: "Ret Nedeni",
            dataIndex: "rejectDescription",
            render: (rejectDescription,item) => {
                if(item.reject===true) {
                    if (item.rejectDescription === null) {
                        return null;
                    } else{
                        return item.rejectDescription;
                    }
                } else {
                    return null;
                }
            },
        },
        {
            id: 10,
            title: "Tarihler",
            key: "dates",
            render: (_, record) => (
                <div>
                    <div><Text type="secondary" style={{ fontSize: isMobile ? '11px' : '12px' }}>Oluşturma: {record.createDate ? dayjs(record.createDate).format('DD.MM.YYYY') : "-"}</Text></div>
                    <div><Text type="secondary" style={{ fontSize: isMobile ? '11px' : '12px' }}>Başlangıç: {record.startDate ? dayjs(record.startDate).format('DD.MM.YYYY') : "-"}</Text></div>
                    <div><Text type="secondary" style={{ fontSize: isMobile ? '11px' : '12px' }}>Bitiş: {record.endDate ? dayjs(record.endDate).format('DD.MM.YYYY') : "-"}</Text></div>
                </div>
            ),
        },
        {
            id: 13,
            title: "Operator",
            dataIndex: "operator",
        },
        {
            title: 'İşlemler',
            key: 'action',
            width: isMobile ? 120 : 180,
            fixed: isMobile ? undefined : 'right',
            render: (_, record) => (
                <Space size={isMobile ? "small" : "middle"} wrap={isMobile}>
                    <Tooltip placement="top" title="İndir">
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            onClick={() => {
                                setSelectedRow(record);
                                setModal3Open(true);
                            }}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Tooltip>
                    {isAdmin === 'ROLE_ADMIN' && (
                    <Tooltip placement="top" title="Onayla">
                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => {
                                setSelectedRow(record);
                                setApproveModalOpen(true);
                            }}
                            style={{ backgroundColor: 'green', borderColor: 'green' }}
                            disabled={record.reject === true || record.approved === true}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Tooltip>
                    )}
                    {isAdmin === 'ROLE_ADMIN' && (
                    <Tooltip placement="top" title="Ret">
                        <Button
                            type="primary"
                            icon={<CloseSquareOutlined />}
                            onClick={() => {
                                setSelectedRow(record);
                                setRejectModalOpen(true);
                            }}
                            style={{ backgroundColor: 'red', borderColor: 'red' }}
                            disabled={record.reject === true || record.approved === true}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows[0]);
        },
    };

    const onTableRowClick = (record) => {
        setSelectedRow(record);
    };

    const handleOk = () => {
        sendRequestForDownload();
        setSelectedRow(null);
    };

    const resetDeleteInput = () => {
        setRejectModalOpen(false)
        setRejectDescription("")
    };

    const handleCancel = () => {
        setSelectedRow(null);
        setModal3Open(false);
    };

    const successMessage = () => {
        message.success('Dosya indirildi.');
    };

    const errorMessage = () => {
        message.error('Dosya indirme işlemi sırasında bir sorun oluştu.');
    };

    const uploadProps = {
        name: 'file',
        multiple: true,
        customRequest: async ({ file, onSuccess, onError }) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('operator', user);

            try {
                const response = await axios.post('/uploadTemp', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                onSuccess(response.data);
                message.success(`${file.name} Yükleme işlemi başarılı.`);
                fetchData();
            } catch (error) {
                onError(error);
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${file.name}.`);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
            }
            if (status === 'done') {
                message.success(`${info.name} yükleme işlemi başarılı`);
                fetchData();
            } else if (status === 'error') {
                message.error(`Yükleme işlemi sırasında bir sorun oluştu ${info.name}.`);
            }
        },
        onDrop(e) {
        },
    };

    if(loading) {
        return (
            <Skeleton active />
        )
    }

    return (
        <div>
            <Modal
                title="Ret Nedeni"
                open={rejectModalOpen}
                onOk={sendRequestForReject}
                onCancel={() => resetDeleteInput()}
                confirmLoading={loading}
                okText="Reddet"
                cancelText="İptal"
            >
                <p>Seçilen kayıt ret edilecektir. Onaylıyor musunuz?</p>
                <TextArea
                    value={rejectDescription}
                    onChange={(e) => setRejectDescription(e.target.value)}
                    rows={4}
                    placeholder="Ret nedenini yazınız."
                />
            </Modal>

            <Modal
                title="Dosya Onayı"
                open={approveModalOpen}
                onOk={sendRequestForApproval}
                onCancel={() => setApproveModalOpen(false)}
                confirmLoading={loading}
                okText="Onayla"
                cancelText="İptal"
            >
                <p>Seçilen kaydı onaylanacaktır. Onaylıyor musunuz?</p>
            </Modal>

            <Modal
                title="Dosya İndirme"
                open={modal3Open}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={loading}
                okText="İndir"
                cancelText="İptal"
            >
                <p>Seçilen dosya indirilecektir. İndirmek istiyor musunuz?</p>
                {selectedRow && (
                    <div style={{ margin: '15px 0' }}>
                        <div><strong>Belge Türü:</strong> {getDocumentTypeName(selectedRow.fileType)}</div>
                        {selectedRow.company && <div><strong>Mağaza:</strong> {selectedRow.company.name}</div>}
                        {selectedRow.personel && <div><strong>Personel:</strong> {selectedRow.personel}</div>}
                        <div><strong>Oluşturma Tarihi:</strong> {selectedRow.createDate ? dayjs(selectedRow.createDate).format('DD.MM.YYYY HH:mm:ss') : "-"}</div>
                    </div>
                )}
            </Modal>

            <Modal
                title="Dosya Yükle"
                visible={modal1Open}
                onOk={() => setModal1Open(false)}
                onCancel={() => setModal1Open(false)}
                footer={null}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
                    </p>
                </Dragger>
            </Modal>

            <div style={{ padding: '0 0 16px' }}>
                <Space align="center" style={{ marginBottom: 16, width: '100%', justifyContent: "space-between", flexWrap: 'wrap' }}>
                    <Input.Search
                        placeholder="Ara... (Mağaza, Personel, Belge Türü)"
                        allowClear
                        onSearch={handleSearch}
                        onChange={e => handleSearch(e.target.value)}
                        style={{ width: isMobile ? '100%' : 300, marginBottom: isMobile ? 8 : 0 }}
                        prefix={<SearchOutlined />}
                    />
                    
                    <Button
                        type="primary"
                        onClick={() => {
                            fetchData();
                            setSearchText("");
                        }}
                        icon={<FilterOutlined />}
                    >
                        Filtreleri Temizle
                    </Button>
                </Space>
            </div>

            <div className="table-responsive-container">
                <Table
                    rowSelection={{
                        type: "radio",
                        ...rowSelection,
                    }}
                    columns={columns}
                    dataSource={filteredData.map((item) => ({...item, key: item.id}))}
                    onRow={(record) => ({
                        onClick: () => onTableRowClick(record),
                    })}
                    scroll={{ 
                        x: isMobile ? 800 : 1200
                    }}
                    pagination={{ 
                        pageSize: isMobile ? 5 : 10,
                        responsive: true,
                        showSizeChanger: !isMobile,
                        showQuickJumper: !isMobile,
                        showTotal: (total) => `Toplam ${total} kayıt`
                    }}
                    style={{ 
                        width: '100%' 
                    }}
                    tableLayout="fixed"
                    size={isMobile ? "small" : "middle"}
                    bordered
                    onHeaderRow={(columns) => {
                        return {
                            style: { 
                                backgroundColor: '#f0f2f5', 
                                fontWeight: 'bold', 
                                fontSize: isMobile ? '12px' : '14px' 
                            }
                        };
                    }}
                    rowClassName={(record) => {
                        const status = getStatusInfo(record);
                        return `custom-row status-${status.text.toLowerCase()}`;
                    }}
                    summary={(pageData) => {
                        const total = pageData.length;
                        const approved = pageData.filter(item => item.approved === true).length;
                        const rejected = pageData.filter(item => item.reject === true).length;
                        const timeout = pageData.filter(item => item.timeout === true).length;
                        const waiting = pageData.filter(item => !item.approved && !item.reject && !item.timeout).length;
                        
                        return (
                            <>
                                {!isMobile && (
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell colSpan={columns.length}>
                                            <Space wrap>
                                                <Tag color="blue">Toplam: {total}</Tag>
                                                <Tag color="green">Onaylı: {approved}</Tag>
                                                <Tag color="red">Reddedilen: {rejected}</Tag>
                                                <Tag color="orange">Süresi Geçmiş: {timeout}</Tag>
                                                <Tag color="cyan">Bekleyen: {waiting}</Tag>
                                            </Space>
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                )}
                            </>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default FileManagement;
