import React, { useEffect, useRef, useState } from 'react';

const JitsiMeet = ({ roomName, jwt, onClose }) => {
    const iframeRef = useRef(null);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleMessage = (event) => {
            // Debug için gelen mesajı logla
            console.log('Gelen mesaj:', event.data);

            // Jitsi Meet'ten gelen mesajları kontrol et
            if (event.data && event.data.type === 'videoConferenceLeft') {
                handleClose();
            }

            // XMPP close mesajını kontrol et
            if (typeof event.data === 'string') {
                // XML string kontrolü
                if (event.data.includes('<close xmlns="urn:ietf:params:xml:ns:xmpp-framing"/>')) {
                    console.log('XMPP close mesajı alındı');
                    handleClose();
                } else if (event.data.includes('<close xmlns="urn:ietf:params:xml:ns:xmpp-framing" />')) {
                    console.log('XMPP close mesajı (alternatif format) alındı');
                    handleClose();
                } else if (event.data.includes('</close>')) {
                    console.log('Alternatif close mesajı alındı');
                    handleClose();
                } else if (event.data.includes('welcome-page') || event.data.includes('<body class="welcome-page">')) {
                    console.log('Welcome page tespit edildi - toplantı bitti');
                    handleClose();
                }
            }

            // Diğer kapatma mesajlarını kontrol et
            if (event.data && (
                event.data.type === 'close' || 
                event.data.type === 'hangup' ||
                event.data.type === 'endConference'
            )) {
                console.log('Kapatma mesajı alındı:', event.data);
                handleClose();
            }
        };

        const handleClose = () => {
            console.log('Toplantı kapatılıyor...');
            
            // Iframe'i temizle
            if (iframeRef.current) {
                // iFrame'in src'sini boşalt
                try {
                    iframeRef.current.src = '';
                } catch (error) {
                    console.error('iframe src temizleme hatası:', error);
                }
            }
            
            // Görünürlüğü kapat
            setIsVisible(false);
            
            // onClose callback'i çağır
            if (onClose) {
                setTimeout(() => {
                    onClose(roomName);
                }, 100);
            }
        };

        // Mesaj dinleyicisini ekle
        window.addEventListener('message', handleMessage);

        // IFrame'in içeriğini kontrol et (welcome-page sınıfı için)
        const checkForWelcomePage = () => {
            try {
                if (iframeRef.current && iframeRef.current.contentDocument) {
                    const welcomePage = iframeRef.current.contentDocument.querySelector('.welcome-page');
                    if (welcomePage) {
                        console.log('Welcome page iframe içinde tespit edildi');
                        handleClose();
                        return true;
                    }
                }
            } catch (e) {
                // Genellikle CORS kısıtlaması nedeniyle contentDocument'e erişim hataları oluşabilir
                console.log('IFrame içeriği kontrol edilemedi:', e);
            }
            return false;
        };

        // Periyodik olarak iframe içeriğini kontrol et
        const intervalId = setInterval(checkForWelcomePage, 2000);

        // Cleanup
        return () => {
            window.removeEventListener('message', handleMessage);
            clearInterval(intervalId);
            
            // Komponent unmount olduğunda iframe'i temizle
            if (iframeRef.current) {
                try {
                    iframeRef.current.src = '';
                } catch (error) {
                    console.error('iframe temizleme hatası:', error);
                }
            }
        };
    }, [roomName, onClose]);

    // Eğer roomName yoksa hiçbir şey gösterme
    if (!roomName || !jwt || !isVisible) {
        return null;
    }

    const meetingUrl = `https://meet.sehoffice.com/${roomName}?jwt=${jwt}`;

    return (
        <div style={{ 
            width: '100%', 
            height: '100%', 
            position: 'relative',
            backgroundColor: '#fff',
            borderRadius: '8px',
            overflow: 'hidden'
        }}>
            <iframe
                ref={iframeRef}
                src={meetingUrl}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '8px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: '#fff'
                }}
                allow="camera; microphone; display-capture"
                onLoad={() => {
                    console.log('Toplantı yüklendi');
                }}
                onError={(e) => {
                    console.error('Toplantı yüklenirken hata oluştu', e);
                    setIsVisible(false);
                    if (onClose) {
                        onClose(roomName);
                    }
                }}
            />
        </div>
    );
};

export default JitsiMeet; 