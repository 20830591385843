import React, { useEffect, useState } from 'react';
import { Upload, Button, Select, message, Tabs, DatePicker } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const FileUploadList = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [personelList, setPersonelList] = useState([]);
    const [selectedPersonel, setSelectedPersonel] = useState(null);
    const [selectedFileType, setSelectedFileType] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileData, setFileData] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    const user = JSON.parse(localStorage.authdata).username;
    const isAdmin = JSON.parse(localStorage.authdata).userRole;

    const sendData = {
        username: user,
    };

    useEffect(() => {
        // Şirket listesini al
        axios.post('/companyList', { method: "companyList", processor: "company", data: sendData })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    const companyList = [...response.data.data];
                    setCompanies(companyList);
                    
                    // Eğer kullanıcı admin değilse ve şirket listesi boş değilse
                    if (isAdmin !== 'ROLE_ADMIN' && companyList.length > 0) {
                        // İlk şirketi seç
                        setSelectedCompany(companyList[0].id);
                        // Seçilen şirketin personel listesini getir
                        fetchPersonelList(companyList[0].id);
                    }
                }
            })
            .catch(error => {
                console.error('Şirket listesi alınırken hata oluştu:', error);
            });

        // Dosya listesi al
        axios.post('/getFiles', { method: "getFiles", processor: "files", data: { username: user } })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setFileData(response.data.data);
                }
            })
            .catch(error => {
                console.error('Dosya listesi alınırken hata oluştu:', error);
            });

        // Admin kullanıcı değilse, personel listesi doğrudan çekilmeyecek
        // Çünkü yukarıda şirket listesi geldiğinde ilk şirketin personel listesi çekilecek
        if (isAdmin === 'ROLE_ADMIN') {
            fetchPersonelList(null);
        }
    }, [user, isAdmin]);

    useEffect(() => {
        // Tab değiştiğinde yapılacak işlemler
        if (activeTab === '1') {
            // Mağaza Seçimi tabına geçildiğinde Personel seçimini temizle
            setSelectedPersonel(null);
            // Belge türünü temizle (her tab için farklı belge türleri var)
            setSelectedFileType(null);
        } else if (activeTab === '2') {
            // Personel Seçimi tabına geçildiğinde belge türünü temizle
            setSelectedFileType(null);
            
            // Admin kullanıcı değilse ve şirket listesi varsa, şirket seçiminin korunduğundan emin olalım
            if (isAdmin !== 'ROLE_ADMIN' && companies.length > 0 && !selectedCompany) {
                setSelectedCompany(companies[0].id);
            }
        }
        
        // Debug için geçerli durumu konsola yazdır
        console.log("Tab değişikliği sonrası durumu:", {
            activeTab,
            selectedCompany,
            selectedPersonel,
            selectedFileType
        });
    }, [activeTab, isAdmin, companies, selectedCompany]);

    const fetchPersonelList = (companyId) => {
        axios.post('/findPersonelListWithCompany', { method: "findPersonelListWithCompany", processor: "personelManagement", data: { id: companyId, operator: user } })
            .then(response => {
                if (response.data.data && Array.isArray(response.data.data)) {
                    setPersonelList(response.data.data);
                }
            })
            .catch(error => {
                console.error('Personel listesi alınırken hata oluştu:', error);
            });
    };

    const handleUpload = ({ file, onSuccess, onError }) => {
        // Zorunlu alan kontrolleri
        if (!selectedFileType) {
            message.error('Lütfen belge türü seçiniz');
            return;
        }
        
        if (isAdmin === 'ROLE_ADMIN' && !selectedCompany) {
            message.error('Lütfen mağaza seçiniz');
            return;
        }
        
        if (activeTab === '2' && !selectedPersonel) {
            message.error('Lütfen personel seçiniz');
            return;
        }
        
        if (!dateRange || !dateRange[0] || !dateRange[1]) {
            message.error('Lütfen tarih aralığı seçiniz');
            return;
        }

        setUploading(true);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', selectedFileType);
        formData.append('username', user);
        formData.append('pathName', file.name.toString());
        
        // Personel tabı için personel ID gönder, diğer tab için null gönder
        if (activeTab === '2') {
            formData.append('personel', selectedPersonel);
        } else {
            formData.append('personel', null);
        }

        // Admin kullanıcı ise seçilen şirketi, değilse otomatik seçilen şirketi gönder
        if (isAdmin === 'ROLE_ADMIN') {
            formData.append('company', selectedCompany);
        } else {
            // Admin olmayan kullanıcılar için selectedCompany değeri kullan (ilk şirket ID'si)
            formData.append('company', selectedCompany || (companies.length > 0 ? companies[0].id : null));
        }

        formData.append('startDate', dateRange[0].valueOf());
        formData.append('endDate', dateRange[1].valueOf());

        // Debug için
        console.log('Gönderilen veriler:', {
            fileType: selectedFileType,
            username: user,
            company: formData.get('company'),
            personel: formData.get('personel'),
            activeTab: activeTab,
            dates: [dateRange[0].format('DD.MM.YYYY'), dateRange[1].format('DD.MM.YYYY')]
        });

        axios.post('/uploadTemp', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                if (response.status === 200) {
                    message.success('Dosya başarıyla yüklendi');
                    onSuccess('OK');
                    setFileData(prevData => [...prevData, response.data.newFile]);
                } else {
                    message.error('Dosya yüklenirken hata oluştu');
                    onError('Error');
                }
            })
            .catch(error => {
                console.error('Dosya yüklenirken hata oluştu:', error);
                message.error('Dosya yüklenirken hata oluştu');
                onError('Error');
            })
            .finally(() => {
                setUploading(false);
            });
    };

    const handleCompanyChange = (value) => {
        setSelectedCompany(value);
        fetchPersonelList(value || null); // Kurum ID'sine göre personel listesini tekrar yükle
    };

    const handleFileTypeChange = (value) => {
        setSelectedFileType(value);
    };

    const handlePersonelChange = (value) => {
        setSelectedPersonel(value);
    };

    const handleDateChange = (dates) => {
        setDateRange(dates);
    };

    const isButtonDisabled = () => {
        // Genel kontroller
        if (!selectedFileType || !dateRange || uploading) {
            return true;
        }
        
        // Tab özel kontroller
        if (activeTab === '1') {
            // Mağaza seçimi tabında admin kullanıcılar için şirket seçimi zorunlu
            if (isAdmin === 'ROLE_ADMIN' && !selectedCompany) {
                return true;
            }
        } else if (activeTab === '2') {
            // Personel seçimi tabında personel seçimi zorunlu
            if (!selectedPersonel) {
                return true;
            }
            // Admin kullanıcılar için şirket seçimi de zorunlu
            if (isAdmin === 'ROLE_ADMIN' && !selectedCompany) {
                return true;
            }
        }
        
        return false;
    };

    return (
        <div>
            <Tabs 
                defaultActiveKey="1" 
                onChange={(key) => {
                    setActiveTab(key);
                    // Tab değişikliğini konsola yazdır (debug için)
                    console.log("Tab değişti:", key);
                }}
            >
                <TabPane tab="Mağaza Seçimi" key="1">
                    {isAdmin === 'ROLE_ADMIN' && (
                        <Select
                            style={{ width: '100%', marginBottom: 16 }}
                            placeholder="Mağaza Seçin"
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                        >
                            {companies.map((company) => (
                                <Option key={company.id} value={company.id}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        placeholder="Belge Türü Seçin"
                        onChange={handleFileTypeChange}
                        value={selectedFileType}
                    >
                        <Option value="RISK_DEGERLENDIRME_RAPORU">Risk Değerlendirme Raporu (RDR)</Option>
                        <Option value="ACIL_DURUM_PLANI">Acil Durum Raporu</Option>
                        <Option value="ISG_UZMANI_ATAMA">İş Sağlığı Güvenliği Uzmanı Atama</Option>
                        <Option value="ISYERI_HEKIMI_ATAMA">İşyeri Hekimi / İş Sağlığı Güvenliği Uzman Hekimi Atama</Option>
                        <Option value="TESPIT_ONERI_DEFTER">Tespit Öneri Defter Nüshası</Option>
                        <Option value="ISVEREN_VEKILI_ATAMA">İşveren Vekili Atama</Option>
                        <Option value="CALISAN_TEMSILCI_ATAMA">Çalışan Temsilci Atama</Option>
                        <Option value="DESTEK_ELEMANI_ATAMA">Destek Elemanı Atama</Option>
                        <Option value="YILLIK_CALISMA_PLANI">Yıllık Çalışma Planı</Option>
                        <Option value="YILLIK_DEGERLENDIRME_RAPORU">Yıllık Değerlendirme Raporu</Option>
                        <Option value="EGITIM_KATILIM_FORMU">Eğitim Katılım Formu</Option>
                    </Select>
                </TabPane>

                <TabPane tab="Personel Seçimi" key="2">
                    {isAdmin === 'ROLE_ADMIN' && (
                        <Select
                            style={{ width: '100%', marginBottom: 16 }}
                            placeholder="Mağaza Seçin"
                            onChange={handleCompanyChange}
                            value={selectedCompany}
                        >
                            {companies.map((company) => (
                                <Option key={company.id} value={company.id}>
                                    {company.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        placeholder="Personel Seçin"
                        onChange={handlePersonelChange}
                        value={selectedPersonel}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {personelList.map((person) => (
                            <Option key={person.id} value={person.id} label={`${person.name} ${person.surname}`}>
                                {person.name} {person.surname}
                            </Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: '100%', marginBottom: 16 }}
                        placeholder="Belge Türü Seçin"
                        onChange={handleFileTypeChange}
                        value={selectedFileType}
                    >
                        <Option value="ISG_EGITIM_RAPORU">İSG Eğitim Belgesi</Option>
                        <Option value="PERIYODIK_SAGLIK_RAPORU">Periyodik Sağlık Raporu</Option>
                        <Option value="TAHLIL_SONUCLARI">Tahlil Sonuçları</Option>
                        <Option value="ISG_TAAHHUTNAME">İSG Taahhütname</Option>
                        <Option value="SON_TEST">Son Test</Option>
                        <Option value="ON_TEST">Ön Test</Option>

                    </Select>
                </TabPane>
            </Tabs>

{/*            {selectedFileType !== 'Type10' && (
                <RangePicker
                    style={{ width: '100%', marginBottom: 16 }}
                    onChange={handleDateChange}
                    value={dateRange}
                    placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                />
            )}*/}

            <RangePicker
                style={{ width: '100%', marginBottom: 16 }}
                onChange={handleDateChange}
                value={dateRange}
                placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            />

            <Upload
                customRequest={handleUpload} // handleUpload fonksiyonu burada tanımlandı
                showUploadList={false}
            >
                <Button
                    icon={<UploadOutlined />}
                    style={{ width: '100%' }}
                    disabled={isButtonDisabled()}
                >
                    {uploading ? 'Yükleniyor...' : 'Dosya Yükle'}
                </Button>
            </Upload>
        </div>
    );
};

export default FileUploadList;
