import React from "react";
import { Row, Col, Card, Form, Input, Button, message } from "antd";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios from "axios";

// Güzel bir ikon dosyası (örnek olarak bir harita işaretçisi ikonunu kullanıyoruz)
const customIcon = new L.Icon({
    iconUrl: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',  // Burada istediğin ikonu kullanabilirsin
    iconSize: [38, 38], // İkonun boyutu
    iconAnchor: [22, 38], // İkonun ucunun işaret edeceği nokta
    popupAnchor: [0, -38], // Popup'ın ikon üzerinde nerede açılacağını belirler
});

const ContactUs = () => {
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {

        try {
            const sendData = {
                method: "contact",
                processor: "contact",
                data: {
                    name: values.name,
                    email: values.email,
                    message: values.message,
                    operator: "user",
                    messageType: "contact"
                }
            };

            const response = await axios.post('/contact', sendData);

            // Eğer API farklı bir anahtar ile başarıyı döndürüyor ise burada kontrol edin
            if (response.data && response.data.success) {
                message.success("Mesajınız başarıyla gönderildi.");
                form.resetFields(); // Başarılı olursa formu sıfırlıyoruz
            } else {
                message.error("Mesaj gönderilemedi.");
            }
        } catch (error) {
            console.error("Mesaj gönderilirken hata oluştu:", error);
            message.error("Mesaj gönderilirken bir hata oluştu.");
        }
    };

    return (
        <div style={{ padding: '40px', backgroundColor: '#f0f2f5', minHeight: '100vh' }}>
            <Row gutter={[24, 24]} justify="center">
                {/* Form Section */}
                <Col xs={24} md={12} lg={10}>
                    <Card
                        title="Contact Us"
                        bordered={false}
                        style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="name"
                                label="Your Name"
                                rules={[{ required: true, message: 'Please enter your name' }]}
                            >
                                <Input placeholder="Enter your name" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Email Address"
                                rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}
                            >
                                <Input placeholder="Enter your email" />
                            </Form.Item>
                            <Form.Item
                                name="message"
                                label="Your Message"
                                rules={[{ required: true, message: 'Please enter your message' }]}
                            >
                                <Input.TextArea rows={4} placeholder="Enter your message" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>

                {/* Map Section */}
                {/*<Col xs={24} md={12} lg={10}>*/}
                {/*    <Card*/}
                {/*        bordered={false}*/}
                {/*        style={{boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}*/}
                {/*    >*/}
                {/*        <MapContainer*/}
                {/*            style={{height: '400px', borderRadius: '8px'}}*/}
                {/*            center={[39.9690895, 32.5485766]}*/}
                {/*            zoom={13}*/}
                {/*        >*/}
                {/*            <TileLayer*/}
                {/*                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
                {/*                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'*/}
                {/*            />*/}
                {/*            <Marker position={[39.9690895, 32.5485766]} icon={customIcon}>*/}
                {/*                <Popup>Your office location here.</Popup>*/}
                {/*            </Marker>*/}
                {/*        </MapContainer>*/}

                {/*        /!* Adres kısmı *!/*/}
                {/*        <div style={{paddingTop: '16px', textAlign: 'left', fontSize: '16px'}}>*/}
                {/*            <strong>Adres:</strong> 6 1. Organize Sanayi Bölgesi, Oğuz Cd., 06935 Sincan/Ankara*/}
                {/*        </div>*/}
                {/*        <div style={{paddingTop: '16px', textAlign: 'left', fontSize: '16px'}}>*/}
                {/*            <strong>Telefon:</strong> +90 (312) 267 07 12*/}
                {/*        </div>*/}
                {/*        <div style={{paddingTop: '16px', textAlign: 'left', fontSize: '16px'}}>*/}
                {/*            <strong>Email:</strong> info@firma.com*/}
                {/*        </div>*/}
                {/*    </Card>*/}
                {/*</Col>*/}
            </Row>
        </div>
    );
};

export default ContactUs;
