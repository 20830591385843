import React, {createContext, useContext, useReducer} from 'react';

const MainContext = createContext();


const initialState = {
    authData: { signedIn:null },
    user: {}
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return {
                ...state,
                authData: action.payload
            };
        case 'SIGN_OUT':
            return {
                ...state,
                authData: { signedIn:false },
                user: {},
            };

        case 'GLOBAL_USER':
            return {
                ...state,
                user: action.payload,
            };

        default:
            return state;
    }
};


const ContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <MainContext.Provider value={[state, dispatch]}>
            {children}
        </MainContext.Provider>
    );
};

export {MainContext, ContextProvider, useContext};
