import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Select, Input, message } from 'antd';
import axios from 'axios';
import JitsiMeet from '../../components/JitsiMeet';

const { Option } = Select;

const Meet = () => {
    const [meetModalVisible, setMeetModalVisible] = useState(false);
    const [meetForm] = Form.useForm();
    const [meetLoading, setMeetLoading] = useState(false);
    const [meetRoom, setMeetRoom] = useState(null);
    const [meetJwt, setMeetJwt] = useState(null);
    const [showMeetingArea, setShowMeetingArea] = useState(false);
    const user = JSON.parse(localStorage.authdata).username;

    useEffect(() => {
        // Toplantı odası değiştiğinde toplantı alanını göster veya gizle
        if (meetRoom) {
            setShowMeetingArea(true);
        } else {
            setShowMeetingArea(false);
        }
    }, [meetRoom]);

    const handleMeetCreate = async (values) => {
        setMeetLoading(true);
        try {
            const response = await axios.post('/createMeet', {
                method: "createMeet",
                processor: "meet",
                data: {
                    operator: user,
                    moderator: values.moderator,
                    record: values.record,
                    roomName: values.roomName
                }
            });

            if (response.data && response.data.success && response.data.data && response.data.data.body) {
                const { jwt, meetingUrl, roomName } = response.data.data.body;
                setMeetJwt(jwt);
                setMeetRoom(roomName);
                setMeetModalVisible(false);
                message.success('Toplantı başarıyla oluşturuldu');
            } else {
                message.error('Toplantı oluşturulurken bir hata oluştu');
            }
        } catch (error) {
            console.error('Toplantı oluşturma hatası:', error);
            message.error('Toplantı oluşturulurken bir hata oluştu');
        }
        setMeetLoading(false);
    };

    const handleMeetClose = async (roomName) => {
        try {
            await axios.post('/closeMeet', {
                method: "closeMeet",
                processor: "meet",
                data: {
                    roomName: roomName,
                    operator: user,
                    moderator: meetForm.getFieldValue('moderator'),
                    record: meetForm.getFieldValue('record')
                }
            });
            message.success('Toplantı sonlandırıldı');
            
            // Temizleme işlemleri
            setMeetRoom(null);
            setMeetJwt(null);
            setShowMeetingArea(false);
            meetForm.resetFields();
        } catch (error) {
            message.error('Toplantı sonlandırılırken bir hata oluştu');
        }
    };

    return (
        <div style={{ padding: '24px' }}>
            <Card title="Toplantı Yönetimi">
                {!showMeetingArea ? (
                    <Button 
                        type="primary" 
                        onClick={() => setMeetModalVisible(true)}
                    >
                        Toplantı Başlat
                    </Button>
                ) : null}

                {showMeetingArea && meetRoom && (
                    <div style={{ 
                        marginTop: '20px',
                        height: 'calc(100vh - 200px)',
                        minHeight: '500px',
                        width: '100%'
                    }}>
                        <JitsiMeet 
                            roomName={meetRoom}
                            jwt={meetJwt}
                            onClose={handleMeetClose}
                        />
                    </div>
                )}
            </Card>

            <Modal
                title="Toplantı Oluştur"
                open={meetModalVisible}
                onCancel={() => {
                    setMeetModalVisible(false);
                    meetForm.resetFields();
                }}
                footer={null}
                width={400}
            >
                <Form
                    form={meetForm}
                    layout="vertical"
                    onFinish={handleMeetCreate}
                >
                    <Form.Item
                        name="roomName"
                        label="Toplantı Adı"
                        rules={[{ required: true, message: 'Lütfen toplantı adını giriniz!' }]}
                    >
                        <Input placeholder="Toplantı adını giriniz" />
                    </Form.Item>
                    <Form.Item
                        name="moderator"
                        label="Moderatör Olarak Katıl"
                        initialValue="true"
                    >
                        <Select>
                            <Option value="true">Evet</Option>
                            <Option value="false">Hayır</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="record"
                        label="Video kaydı alınsın"
                        initialValue="false"
                    >
                        <Select>
                            <Option value="true">Evet</Option>
                            <Option value="false">Hayır</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={meetLoading}>
                            Toplantı Oluştur
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Meet; 